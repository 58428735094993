import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  AutoSizer as _AutoSizer,
  AutoSizerProps,
  List as _List,
  ListProps,
  ListRowRenderer,
  InfiniteLoader as _InfiniteLoader,
  InfiniteLoaderProps,
} from 'react-virtualized';
import { throttle } from 'throttle-debounce';
import { SideActionCard, Spinner, InventoryCard } from 'components';
import { useWindowDimensions } from 'hooks';
import { breakpoints } from '../../../constants';
import {
  inventorySelector,
  useAppDispatch,
  resetCurrentItemInventory,
  InventoryItem,
  configSelector,
} from 'store';
import { getInventoryItems, setCurrentItem } from 'store/inventory-slice';
import { EmptyInventoryTab } from './empty-inventory-tab';
import {
  InventoryDetailedCard,
  InventoryDetailedCardMobile,
} from './inventory-detailed-card';
import InventoryTopRightImage from 'assets/images/inventory-right-top-card.svg';
import MarketplaceRightBottomImage from 'assets/images/marketplace-right-bottom-card.svg';

const List = _List as unknown as FC<ListProps>;
const AutoSizer = _AutoSizer as unknown as FC<AutoSizerProps>;
const InfiniteLoader = _InfiniteLoader as unknown as FC<InfiniteLoaderProps>;

export type InventoryTabProps = {
  goToMarketplaceTab: () => void;
};

const InventoryTab: FC<InventoryTabProps> = ({ goToMarketplaceTab }) => {
  const { inventoryData, loading, shouldLoadMore, currentItem } =
    useSelector(inventorySelector);
  const { refreshRate, pageCount, pageItemSize } = useSelector(configSelector);
  const dispatch = useAppDispatch();
  const { width } = useWindowDimensions();

  const isMobile = width < breakpoints.md;

  const [currentPage, setCurrentPage] = useState(2);

  useEffect(() => {
    dispatch(
      getInventoryItems({
        page: 1,
        isInitialLoading: true,
        limit: pageItemSize * pageCount,
      })
    );
  }, []);

  const rowRenderer: (itemsPerRow: number) => ListRowRenderer =
    (itemsPerRow) =>
    ({ index, key, style }) => {
      const _items = [];
      const fromIndex = index * itemsPerRow;
      const toIndex = Math.min(fromIndex + itemsPerRow, inventoryData.length);

      const itemWrapper = (item: InventoryItem) => (
        <InventoryCard
          key={item.id}
          geo={item.marketplaceItem.geoGroup}
          name={item.name}
          type={item.type}
          image={item.imageUrl}
          category={item.marketplaceItem.category}
          item={item}
          currentItemId={currentItem?.id}
          onClick={(item) => {
            if (item.id === currentItem?.id) {
              dispatch(resetCurrentItemInventory());
            } else {
              dispatch(setCurrentItem(item));
            }
          }}
        />
      );

      for (let i = fromIndex; i < toIndex; i++) {
        _items.push(itemWrapper(inventoryData[i]));
      }

      return (
        <div
          key={key}
          style={style}
          className="grid w-full h-fit grid-cols-[repeat(auto-fill,_168px)] md:grid-cols-[repeat(auto-fill,_241px)] gap-[10px] justify-center"
        >
          {_items}
        </div>
      );
    };

  const noRowsRenderer = () => {
    return <EmptyInventoryTab goToMarketplaceTab={goToMarketplaceTab} />;
  };

  const getRowHeight = () => {
    return isMobile ? 274 : 310;
  };

  const loadMoreData = throttle(
    Number(process.env.REACT_APP_THROTTLE_DELAY_IN_MS) || 1000,
    () => {
      if (shouldLoadMore) {
        dispatch(
          getInventoryItems({
            page: currentPage,
            limit: pageItemSize * pageCount,
            isInitialLoading: false,
          })
        );
        setCurrentPage((p) => p + 1);
      }
    },
    { noLeading: false, noTrailing: false }
  ) as unknown as () => Promise<void>;

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentPage <= 2) {
        dispatch(
          getInventoryItems({
            page: 1,
            isInitialLoading: true,
            shouldDisableLoading: true,
            limit: pageItemSize * pageCount,
          })
        );
      } else {
        dispatch(
          getInventoryItems({
            page: 1,
            limit: pageItemSize * pageCount,
            isInitialLoading: true,
            shouldDisableLoading: true,
          })
        );
      }
    }, refreshRate * 1000);

    return () => clearInterval(interval);
  }, [currentPage]);

  return (
    <div className="pt-[9.5px] pb-[14px] flex h-full ">
      {loading ? (
        <Spinner />
      ) : (
        <div className="flex h-full w-full mt-[30px] max-w-full md:max-w-[calc(100%-400px)]  pr-[8px]">
          <InfiniteLoader
            isRowLoaded={() => loading}
            loadMoreRows={loadMoreData}
            // we do not know the total count of items from the api. so the logic of not performing the request is happening on our side
            rowCount={100000000000}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer>
                {({ width, height }: { width: number; height: number }) => {
                  const itemWidth = isMobile ? 168 : 241;
                  const itemsPerRow = Math.floor(width / itemWidth);
                  const rowCount = Math.ceil(
                    inventoryData.length / itemsPerRow
                  );

                  return (
                    <List
                      ref={registerChild}
                      onRowsRendered={onRowsRendered}
                      height={height}
                      noRowsRenderer={noRowsRenderer}
                      rowCount={rowCount}
                      rowHeight={getRowHeight}
                      rowRenderer={rowRenderer(itemsPerRow)}
                      width={width}
                    />
                  );
                }}
              </AutoSizer>
            )}
          </InfiniteLoader>
        </div>
      )}
      <div className="max-h-[calc(100vh-70px)] h-full hidden md:flex md:flex-col fixed top-[36px] right-[10px] z-[50]">
        <SideActionCard className="flex items-center justify-center flex-1 !p-0 bg-primary mb-[10px] h-[50%] overflow-hidden w-[400px]">
          {currentItem ? (
            <InventoryDetailedCard item={currentItem} />
          ) : (
            <img
              src={InventoryTopRightImage}
              alt="Level Up"
              className="h-full"
            />
          )}
        </SideActionCard>
        <SideActionCard className="flex items-center justify-center flex-1 h-[50%] overflow-hidden w-[400px]">
          <img src={MarketplaceRightBottomImage} alt="Buff Promo" />
        </SideActionCard>
      </div>
      {isMobile ? (
        <InventoryDetailedCardMobile
          item={currentItem}
          isOpened={Boolean(currentItem)}
        />
      ) : null}
    </div>
  );
};

export { InventoryTab };
