import { defaultSortingValue } from 'constants/marketplace';
import { api } from './axios-client';
import { urls } from './constants';

export type MarketplaceParams = {
  page: number;
  limit?: number;
  category?: (string | number)[];
  regions?: (string | number)[];
  type?: (string | number)[];
  onlyAvailableNow?: boolean;
  sort?: string;
};

export type SendCodeBody = { id: string };

export type BuyItemBody = { itemId: string; code: number };

const getMarketplaceItems = async (params: MarketplaceParams) => {
  const response = await api.get(urls.marketplace, {
    params: { ...params, sort: params.sort || defaultSortingValue },
  });

  return response;
};

const getMarketplaceItem = async (id: string) => {
  const response = await api.get(urls.marketplaceItem.replace('{{id}}', id));

  return response;
};

const getMarketplaceFilters = async () => {
  const response = await api.get(urls.marketplaceFilters);

  return response;
};

const postConfirmationCode = async (body: SendCodeBody) => {
  const response = await api.post(urls.marketplaceItemSendCode, body);

  return response;
};

const postBuyItem = async (body: BuyItemBody) => {
  const response = await api.post(urls.marketplaceItemBuy, body);

  return response;
};

export {
  getMarketplaceItems,
  getMarketplaceFilters,
  getMarketplaceItem,
  postConfirmationCode,
  postBuyItem,
};
