import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getInventoryItems as getInventoryItemsApi,
  InventoryParams,
} from '../api/inventory';
import { InventoryItem } from '../store/types/inventory';
import { store } from './configure-store';

const inventoryItemCategory = {
  rafflePrize: 'raffle prize',
};

const getInventoryItems = createAsyncThunk(
  'inventory/items',
  async (
    params: InventoryParams & {
      isInitialLoading: boolean;
      shouldDisableLoading?: boolean;
    }
  ) => {
    const { isInitialLoading, shouldDisableLoading, ...resolvedParams } =
      params;
    const { config } = store.getState();

    const response = await getInventoryItemsApi(resolvedParams);
    return { ...response?.data, pageItemSize: config.pageItemSize };
  }
);

type inventoryState = {
  inventoryData: InventoryItem[];
  loading: boolean;
  loadingMore: boolean;
  shouldLoadMore: boolean;
  itemsOverlay: boolean;
  currentItem?: InventoryItem;
  filters: {
    types: [];
    geo: [];
    categories: [];
    sorting: {};
  };
};

export const inventoryInitialState: inventoryState = {
  inventoryData: [],
  loading: false,
  loadingMore: false,
  shouldLoadMore: true,
  currentItem: undefined,
  itemsOverlay: false,
  filters: {
    types: [],
    geo: [],
    categories: [],
    sorting: {},
  },
};

const inventorySlice = createSlice({
  name: 'inventory',
  initialState: inventoryInitialState,
  reducers: {
    setCurrentItem: (
      state,
      action: PayloadAction<InventoryItem | undefined>
    ) => {
      state.currentItem = action.payload;
      state.itemsOverlay = true;
    },
    resetCurrentItemInventory: (state) => {
      state.currentItem = undefined;
      state.itemsOverlay = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInventoryItems.pending, (state, action) => {
      if (
        action.meta.arg.isInitialLoading &&
        !action.meta.arg.shouldDisableLoading
      ) {
        state.loading = true;
      } else {
        state.loadingMore = true;
      }
    });

    builder.addCase(getInventoryItems.fulfilled, (state, action) => {
      if (action.meta.arg.isInitialLoading) {
        state.loading = false;
        state.inventoryData = action.payload.data;
        state.shouldLoadMore = true;
      } else {
        state.loadingMore = false;
        state.inventoryData.push(...action.payload.data);
      }

      if (
        action.payload.count < action.payload.pageItemSize ||
        action.payload.data?.length === 0
      ) {
        state.shouldLoadMore = false;
      }
    });

    builder.addCase(getInventoryItems.rejected, (state, action) => {
      state.loading = false;
    });
  },
});
export const { setCurrentItem, resetCurrentItemInventory } =
  inventorySlice.actions;
const inventoryReducer = inventorySlice.reducer;

export { getInventoryItems, inventoryReducer, inventoryItemCategory };
