import { FC, useMemo, useState, useEffect } from 'react';
import { t, TFunction } from 'i18next';
import c from 'classnames';
import {
  resetCurrentItemInventory,
  InventoryItem,
  useAppDispatch,
} from 'store';
import { parseCode } from 'utils';
import { Icon, Tabs } from 'components';
import { DescriptionTab } from './description-tab';
import { HowToRedeemTab } from './how-to-redeem-tab';
import { GiftCodesTab } from './gift-codes-tab';

export type InventoryDetailedCardProps = {
  item: InventoryItem;
};

const InventoryDetailedCard: FC<InventoryDetailedCardProps> = ({ item }) => {
  const dispatch = useAppDispatch();

  const removeCurrentItemHandler = () => {
    dispatch(resetCurrentItemInventory());
  };

  const code = item?.marketplaceItem.giftCode;
  const parsedCodes = parseCode(code);

  const descriptionTabId = '1';
  const howToRedeemTabId = '2';
  const giftCodesTab = '3';

  const makeTabs = (
    t: TFunction<'translation', undefined>,
    secondTabPresent?: boolean,
    thirdTabPresent?: boolean
  ) => [
    {
      id: descriptionTabId,
      title: secondTabPresent ? t('Tab.InventoryCardDetails.Description') : '',
    },
    ...(secondTabPresent
      ? [
          {
            id: howToRedeemTabId,
            title: t('Tab.InventoryCardDetails.HowToRedeem'),
          },
        ]
      : []),
    ...(thirdTabPresent
      ? [
          {
            id: giftCodesTab,
            title: t('Tab.InventoryCardDetails.Codes'),
          },
        ]
      : []),
  ];

  const tabsContent = [
    {
      id: descriptionTabId,
      component: (
        <DescriptionTab
          parsedCode={parsedCodes?.length === 1 ? parsedCodes[0] : undefined}
          onlyOneTab={
            !Boolean(item.marketplaceItem.howToRedeem) &&
            (!parsedCodes?.length || parsedCodes?.length === 1)
          }
        />
      ),
    },
    ...(item.marketplaceItem.howToRedeem
      ? [
          {
            id: howToRedeemTabId,
            component: (
              <HowToRedeemTab howToRedeem={item.marketplaceItem.howToRedeem} />
            ),
          },
        ]
      : []),
    ...(parsedCodes && parsedCodes?.length > 1
      ? [
          {
            id: giftCodesTab,
            component: <GiftCodesTab codes={parsedCodes} />,
          },
        ]
      : []),
  ];

  const tabTitles = useMemo(
    () =>
      makeTabs(
        t,
        Boolean(item.marketplaceItem.howToRedeem),
        Boolean(parsedCodes && parsedCodes?.length > 1)
      ),
    [t, item?.marketplaceItem?.howToRedeem, parsedCodes?.length]
  );

  const [activeTab, setActiveTab] = useState(descriptionTabId);

  useEffect(() => {
    setActiveTab(descriptionTabId);
  }, [item]);

  return (
    <div className="h-full w-[400px] bg-dark-card rounded-lg relative">
      <div
        className={c(
          'pt-[9px] px-[20px] flex items-center justify-between mb-[10px] w-full'
        )}
      >
        <p
          className={c(
            'text-railroad font-bold text-[18px] leading-[26px]',
            {}
          )}
        >
          {item.name}
        </p>
        <div
          className={c('flex items-center justify-center', {})}
          onClick={removeCurrentItemHandler}
        >
          <Icon name="closeWhite" className="cursor-pointer" />
        </div>
      </div>
      <div className="text-gray-text h-[90%]">
        <div className="py-[10x] bg-dark-card h-[96%]">
          <Tabs
            shouldDisableBorder={!Boolean(item.marketplaceItem.howToRedeem)}
            className="h-full w-[100%]"
            tabsContainerClassName={c('ml-[20px]', {})}
            tabsClassName="!px-[0px] mr-[16px] font-bold tracking-[0.02em]"
            activeTabId={activeTab}
            onChange={setActiveTab}
            titles={tabTitles}
            content={tabsContent}
          />
        </div>
      </div>
    </div>
  );
};

export { InventoryDetailedCard };
