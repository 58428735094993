const parseByNewLines = ({
  str,
  classNameForSpan,
}: {
  str?: string | null;
  classNameForSpan?: string;
}): JSX.Element => {
  if (!str) {
    return <></>;
  }

  const result = str.split(/\r?\n/).map((r, index) => {
    return (
      <span className={classNameForSpan} key={index}>
        {r}
        <br />
      </span>
    );
  });

  return <>{result}</>;
};

const removeQuotes = (incomingStr: unknown) => {
  const str = String(incomingStr);
  const quotes = ["'", '"'];
  let result = str;

  if (quotes.includes(str[0])) {
    result = str.slice(1);
  }
  if (quotes.includes(str[str.length - 1])) {
    result = result.slice(0, -1);
  }
  return result;
};

const parseCode = (code?: string) => {
  if (!code) return;
  try {
    const JSONstring = JSON.parse(code);
    if (typeof JSONstring === 'string') {
      return [removeQuotes(JSONstring)];
    }
    const strings = Object.values(JSONstring).filter(Boolean).map(removeQuotes);
    return strings;
  } catch (e) {
    return [removeQuotes(code)];
  }
};
export { parseByNewLines, removeQuotes, parseCode };
