import { FC, useMemo, useEffect, useCallback } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'components';
import {
  marketplaceSelector,
} from 'store';
import { MarketplaceTab } from './marketplace-tab';
import { InventoryTab, InventoryTabProps } from './inventory-tab';
import { useSelector } from 'react-redux';
import {
  MarketplaceTabProps,
  Sorting,
  useMarketplaceFilters,
} from './marketplace-tab';
import { breakpoints } from 'constants/breakpoints';
import { useWindowDimensions } from 'hooks';
import useAuth from "../../hooks/useAuth";

const marketplaceTabId = '1';
const inventoryTabId = '2';

const makeTabs = (t: TFunction<'translation', undefined>) => [
  {
    id: marketplaceTabId,
    title: t('Tabs.Marketplace.Title'),
  },
  {
    id: inventoryTabId,
    title: t('Tabs.Inventory.Title'),
  },
];

const makeTabsContent = ({
  marketplaceProps,
  inventoryProps,
}: {
  marketplaceProps: MarketplaceTabProps;
  inventoryProps: InventoryTabProps;
}) => [
  {
    id: marketplaceTabId,
    component: <MarketplaceTab {...marketplaceProps} />,
  },
  {
    id: inventoryTabId,
    component: <InventoryTab {...inventoryProps} />,
  },
];

export type MainPageProps = {};

const MainPage: FC<MainPageProps> = () => {
  const { t } = useTranslation();
  const { isLoading } = useAuth();

  const tabTitles = useMemo(() => makeTabs(t), [t]);
  const values = useMarketplaceFilters();

  const [activeTab, setActiveTab] = useQueryParam('active-tab', StringParam);

  const goToMarketplaceTab = useCallback(() => {
    setActiveTab(marketplaceTabId);
  }, []);

  useEffect(() => {
    if (!activeTab) {
      setActiveTab(marketplaceTabId);
    }
  }, []);

  const tabsContent = useMemo(
    () =>
      makeTabsContent({
        marketplaceProps: values,
        inventoryProps: {
          goToMarketplaceTab,
        },
      }),
    [values]
  );

  const { showMobileFilters } = useSelector(marketplaceSelector);
  const { width } = useWindowDimensions();
  const isMobile = width < breakpoints.md;

  return (
    <div className="bg-dark min-h-screen h-full text-gray-text">
      <div className="py-[12px] px-[10px] h-full">
        {isLoading ? null : (
          <Tabs
            tabsContainerClassName="w-full md:w-[calc(100%-410px)] h-[50px] pb-[10px] pt-[12px] fixed top-[0px] z-50 bg-black mx-[auto]"
            className="h-full overflow-hidden"
            activeTabId={activeTab || marketplaceTabId}
            onChange={setActiveTab}
            titles={tabTitles}
            content={tabsContent}
            isMobile={isMobile}
            showMobileFilters={showMobileFilters}
            contentOnLeft={
              <Sorting
                shouldBeShown={activeTab !== inventoryTabId}
                values={values}
              />
            }
          />
        )}
      </div>
    </div>
  );
};

export { MainPage };
