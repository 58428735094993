import c from 'classnames';
import { Icon } from 'components/common';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export type PriceBlockProps = {
  price: number | string;
  isPremium?: boolean;
  showDefaultLabel?: boolean;
  className?: string;
};

const PriceBlock: FC<PriceBlockProps> = ({
  price,
  isPremium,
  className,
  showDefaultLabel,
}) => {
  const { t } = useTranslation();

  return (
    <div className={c('', className)}>
      <p className="typography-card-label font-grotesk font-semibold text-gray-text-card mb-[4px]">
        {t(
          isPremium && !showDefaultLabel
            ? 'MarketplaceCard.PremiumPriceLabel'
            : 'MarketplaceCard.PriceLabel'
        )}
      </p>
      <div className="flex items-center">
        <span className="mr-[6px] typography-basic font-grotesk font-bold">
          {price}
        </span>
        <Icon name={isPremium ? 'premiumPrice' : 'regularPrice'} />
      </div>
    </div>
  );
};

export { PriceBlock };
