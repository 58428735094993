const urls = {
  baseUrl: process.env.REACT_APP_API_BASE_URL,

  signIn: '/api/auth/local/sign-in',

  user: '/api/accounts/current',

  marketplace: '/api/marketplace/items',
  marketplaceItem: '/api/marketplace/items/{{id}}',
  marketplaceItemSendCode: '/api/marketplace/send-confirmation-code',
  marketplaceItemBuy: '/api/marketplace/items/buy-item',

  marketplaceFilters: '/api/marketplace/filters',

  inventory: '/api/inventory/my',
};

export { urls };
