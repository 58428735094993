import { defaultSortingValue } from './../../../constants/marketplace';
import { useTranslation } from 'react-i18next';
import { useMemo, useCallback, useEffect } from 'react';
import {
  useQueryParam,
  BooleanParam,
  ArrayParam,
  StringParam,
} from 'use-query-params';
import { Option } from 'components';
import { useSelector } from 'react-redux';
import { marketplaceSelector } from 'store';
import { makeSortingOptions } from 'constants/marketplace';

export type MarketplaceFilter = {
  selected: Option['value'][];
  onChange: (selectedOptions: Option['value'][]) => void;
  options: Option[];
};

export type FilterType = 'categories' | 'type' | 'geo';

export type MarketplaceFilters = {
  categories: MarketplaceFilter;
  geo: MarketplaceFilter;
  type: MarketplaceFilter;
  sorting: {
    selected: Option['value'];
    onChange: (selectedOption: Option | (string | number)[]) => void;
    options: Option[];
  };
  outOfStock: {
    onChange: (isOutOfStockShown: boolean) => void;
    checked: boolean;
  };
  activeFilters: { value: string; type: FilterType }[];
  resetFilters: () => void;
  resetOneFilter: (filter: MarketplaceFilters['activeFilters'][0]) => void;
  filterOptions: Option[];
  onMobileFilterChange: (selectedOption: string | number) => void;
};

const useMarketplaceFilters = (): MarketplaceFilters => {
  const { filters } = useSelector(marketplaceSelector);
  const { t } = useTranslation();

  const [selectedCategories, setSelectedCategories] = useQueryParam(
    'categories',
    ArrayParam
  );
  const [selectedGeo, setSelectedGeo] = useQueryParam('regions', ArrayParam);
  const [selectedSorting, setSelectedSorting] = useQueryParam(
    'sorting',
    StringParam
  );

  useEffect(() => {
    if (!selectedSorting) {
      setSelectedSorting(defaultSortingValue);
    }
  }, []);

  const [selectedTypes, setSelectedTypes] = useQueryParam('type', ArrayParam);
  const [hideOutOfStock, setHideOutOfStock] = useQueryParam(
    'outOfStock',
    BooleanParam
  );

  const categoryOptions = useMemo(
    () =>
      filters.categories.map((cat: string) => ({
        label: cat,
        value: cat,
      })),
    [filters]
  );
  const geoOptions = useMemo(
    () => filters.geo.map((geo) => ({ label: geo, value: geo })),
    [filters]
  );
  const typeOptions = useMemo(
    () =>
      filters.types.map((type: string) => {
        const lowercaseLabel = type.toLowerCase();
        const capitalizedLabel =
          lowercaseLabel.charAt(0).toUpperCase() + lowercaseLabel.slice(1);
        return {
          label: capitalizedLabel,
          value: type,
        };
      }),
    [filters]
  );
  const sortingOptions = useMemo(() => makeSortingOptions(t), [t]);

  const onCategoryChange = (selectedOptions: Option['value'][]) => {
    const values = selectedOptions.map((option) => String(option));
    setSelectedCategories(values);
  };
  const onGeoChange = (selectedOptions: Option['value'][]) => {
    const values = selectedOptions.map((option) => String(option));
    setSelectedGeo(values);
  };
  const onTypeChange = (selectedOptions: Option['value'][]) => {
    const values = selectedOptions.map((option) => String(option));
    setSelectedTypes(values);
  };
  const onSortingChange = (selected: Option | (string | number)[]) => {
    if (!Array.isArray(selected)) {
      setSelectedSorting(String(selected.value));
    }
  };

  const onMobileFilterChange = (selectedOption: string | number) => {
    const stringOption = String(selectedOption);
    if (categoryOptions.find((option) => option.value === stringOption)) {
      if (selectedCategories?.includes(stringOption)) {
        setSelectedCategories((categories) =>
          categories?.filter((val) => val !== stringOption)
        );
      } else {
        setSelectedCategories((categories) => [
          ...((categories as string[]) || []),
          stringOption,
        ]);
      }
    }

    if (geoOptions.find((option) => option.value === stringOption)) {
      if (selectedGeo?.includes(stringOption)) {
        setSelectedGeo((geo) => geo?.filter((val) => val !== stringOption));
      } else {
        setSelectedGeo((geo) => [...((geo as string[]) || []), stringOption]);
      }
    }

    if (typeOptions.find((option) => option.value === stringOption)) {
      if (selectedTypes?.includes(stringOption)) {
        setSelectedTypes((type) => type?.filter((val) => val !== stringOption));
      } else {
        setSelectedTypes((type) => [
          ...((type as string[]) || []),
          stringOption,
        ]);
      }
    }
  };

  const activeFilters = useMemo(
    () =>
      [
        ...new Set([
          ...(selectedCategories || []).map((category) => ({
            value: category || '',
            type: 'categories',
          })),
          ...(selectedGeo || []).map((geo) => ({
            value: geo || '',
            type: 'geo',
          })),
          ...(selectedTypes || []).map((type) => ({
            value: type || '',
            type: 'type',
          })),
        ]),
      ] as MarketplaceFilters['activeFilters'],
    [selectedCategories?.length, selectedGeo?.length, selectedTypes?.length]
  );

  const resetFilters = useCallback(() => {
    onCategoryChange([]);
    onGeoChange([]);
    onTypeChange([]);
    setHideOutOfStock(false);
  }, [onCategoryChange, onGeoChange, onTypeChange, setHideOutOfStock]);

  const resetOneFilter = useCallback(
    (filter: MarketplaceFilters['activeFilters'][0]) => {
      if (filter.type === 'categories') {
        const newValues = (selectedCategories || []).filter(
          (selected) => selected !== filter.value
        );
        onCategoryChange(newValues as string[]);
      }

      if (filter.type === 'geo') {
        const newValues = (selectedGeo || []).filter(
          (selected) => selected !== filter.value
        );
        onGeoChange(newValues as string[]);
      }

      if (filter.type === 'type') {
        const newValues = (selectedTypes || []).filter(
          (selected) => selected !== filter.value
        );
        onTypeChange(newValues as string[]);
      }
    },
    [
      selectedCategories,
      selectedGeo,
      selectedTypes,
      onCategoryChange,
      onGeoChange,
      onTypeChange,
    ]
  );

  const filterOptions: Option[] = [
    ...categoryOptions,
    ...geoOptions,
    ...typeOptions,
  ];

  return {
    categories: {
      selected: (selectedCategories || []) as Option['value'][],
      onChange: onCategoryChange,
      options: categoryOptions,
    },
    geo: {
      selected: (selectedGeo || []) as Option['value'][],
      onChange: onGeoChange,
      options: geoOptions,
    },
    type: {
      selected: (selectedTypes || []) as Option['value'][],
      onChange: onTypeChange,
      options: typeOptions,
    },
    outOfStock: {
      checked: Boolean(hideOutOfStock),
      onChange: setHideOutOfStock,
    },
    sorting: {
      selected: selectedSorting || '',
      onChange: onSortingChange,
      options: sortingOptions,
    },
    activeFilters,
    resetFilters,
    resetOneFilter,
    filterOptions,
    onMobileFilterChange,
  };
};

export { useMarketplaceFilters };
