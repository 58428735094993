import { FC, PropsWithChildren } from 'react';
import c from 'classnames';

export type SideActionCardProps = {
  className?: string;
};

const SideActionCard: FC<PropsWithChildren<SideActionCardProps>> = ({
  children,
  className,
}) => {
  return (
    <div
      className={c(
        'bg-dark-card pt-[9px] pb-[18px] px-[20px] rounded-[8px]',
        className
      )}
    >
      {children}
    </div>
  );
};

export { SideActionCard };
