import { FC, useMemo } from 'react';
import c from 'classnames';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useTranslation } from 'react-i18next';
import { Card, CardProps, Icon, GeoLabel } from '../common';
import { marketplaceItemType } from 'store';
import { PriceBlock, PriceBlockProps } from './price-block';
import './marketplace-card.css';

dayjs.extend(relativeTime);

export type MarketplaceCardProps = Omit<CardProps, 'onClick'> & {
  name: string;
  geo: string | null;
  type: string;
  price: PriceBlockProps['price'];
  premiumPrice?: PriceBlockProps['price'];
  image?: string;
  isOutOfStock?: boolean;
  lastPurchaseDate?: string;
  onClick?: (id: string) => void;
  id: string;
  hasActiveCard?: boolean;
  activeCardId?: string;
};

const MarketplaceCard: FC<MarketplaceCardProps> = ({
  name,
  geo,
  type,
  price,
  premiumPrice,
  image,
  isOutOfStock,
  lastPurchaseDate,
  onClick,
  id,
  hasActiveCard,
  activeCardId,
  ...props
}) => {
  const isPremium =
    type && type.toLowerCase() === marketplaceItemType.premium.toLowerCase();
  const isBuffpay =
    type &&
    type.toLowerCase() === marketplaceItemType.buffpayOnly.toLowerCase();

  const { t } = useTranslation();

  const marketplaceType = useMemo(() => {
    if (isBuffpay) {
      return { text: t('MarketplaceCard.Buffpay') };
    }

    if (isPremium) {
      return { text: t('MarketplaceCard.Premium') };
    }
  }, [type, t, isPremium, isBuffpay]);

  const isActive = activeCardId === id;
  const hasActiveCards = Boolean(activeCardId && !isActive);

  return (
    <Card
      {...props}
      className={c('relative', {
        'border-gold-border border-[1px]':
          isPremium && !isOutOfStock && !hasActiveCards,
      })}
      src={image}
      onClick={() => {
        onClick && onClick(id);
      }}
    >
      {hasActiveCards ? (
        <div className="absolute top-0 right-0 w-full h-full bg-overlay-bg-desktop rounded-lg"></div>
      ) : null}
      <div className="flex items-center mb-[7px] md:mb-[14px] mr-[8px]">
        <GeoLabel geo={geo} />

        {marketplaceType ? (
          <div className="flex items-center">
            <span
              className={c('uppercase typography-basic font-bold', {
                'text-gray-text': isBuffpay,
                'text-gold-primary': isPremium,
              })}
            >
              {marketplaceType.text}
            </span>
          </div>
        ) : null}
      </div>
      <h3 className="typography-card-title uppercase font-bold mb-[4px] marketplace-card-title text-white">
        {name}
      </h3>
      <p className="text-primary typography-card-label flex items-center capitalize font-grotesk font-medium">
        {t('MarketplaceCard.SeeMore')}
        <Icon name="arrowLearnMore" className="ml-[6px]" />
      </p>

      <div className="mt-auto flex items-center">
        {isPremium ? (
          <PriceBlock
            isPremium
            showDefaultLabel
            price={premiumPrice || price}
          />
        ) : premiumPrice ? (
          <>
            <PriceBlock
              isPremium
              price={premiumPrice}
              className="mr-[14px] md:mr-[60px]"
            />
            <PriceBlock price={price} />
          </>
        ) : (
          <></>
        )}
      </div>
      {isOutOfStock ? (
        <>
          <div className="absolute h-full w-full top-0 right-0 bg-card-out-of-stock"></div>
          <div className="absolute py-[6px] px-[8px] border-pink rounded-[4px] border-[1px] bg-dark-card">
            <p className="typography-title mb-[2px]">
              {t('MarketplaceCard.OutOfStock')}
            </p>
            {lastPurchaseDate ? (
              <p className="text-gray-text-card font-grotesk typography-card-label font-bold">
                {t('MarketplaceCard.LastPurchase', {
                  time: dayjs(lastPurchaseDate).fromNow(true),
                })}
              </p>
            ) : null}
          </div>
        </>
      ) : null}
    </Card>
  );
};

export { MarketplaceCard };
