import { FC } from 'react';
import c from 'classnames';
import { t } from 'i18next';
import { useSwipeable } from 'react-swipeable';
import { Button, Icon, MobileFilterItem } from 'components';
import { hideMobileFilters, useAppDispatch } from 'store';
import { useSwipeAnimationMobile } from 'hooks';
import { useMarketplaceFilters } from '../use-marketplace-filters';

export type MobileFiltersProps = {
  isOpened?: boolean;
};

const MobileFilters: FC<MobileFiltersProps> = ({ isOpened }) => {
  const { onMobileFilterChange, filterOptions, activeFilters } =
    useMarketplaceFilters();

  const { onCardClose, styles, isCardOpened } = useSwipeAnimationMobile({
    onClose: () => {
      dispatch(hideMobileFilters());
    },
    isOpened,
  });

  const dispatch = useAppDispatch();

  const handler = useSwipeable({
    onSwipedDown: onCardClose,
  });

  return (
    <>
      {isCardOpened ? (
        <div
          className="fixed top-0 left-0 h-screen w-screen bg-overlay-bg z-[100]"
          onClick={onCardClose}
        />
      ) : null}
      <div
        className={c(
          'w-full h-[458px] fixed bottom-[0px] left-[0px] bg-dark-card opacity-1 z-[101] transition-all',
          styles,
          {
            hidden: !isOpened,
          }
        )}
      >
        <div
          className="w-full h-[12px] flex justify-center items-end mb-[20px]"
          {...handler}
          onClick={onCardClose}
        >
          <div className="w-[80px] h-[4px] rounded-[4px] bg-neutral-70 "></div>
        </div>
        <h3 className="ml-[15px] text-[20px] leading-[26px] font-bold mb-[10px]">
          {t('MobileFilter.Heading')}
        </h3>
        <div className="w-full h-[277px] grid grid-cols-[repeat(auto-fill,_168px)] gap-x-[9px] gap-y-[11px] justify-center overflow-y-auto">
          {filterOptions.map((item) => (
            <MobileFilterItem
              id={item.value}
              label={item.label}
              key={item.value}
              onClick={(item) => {
                item && onMobileFilterChange(item);
              }}
              isActive={Boolean(
                activeFilters.find(
                  (activeValue) => activeValue.value === item.value
                )
              )}
            />
          ))}
        </div>
        <div className="w-full h-[1px] px-[15px] bg-neutral-30 mt-[20px]"></div>
        <div className="w-full h-[88px] flex items-center justify-center">
          <Button
            className="w-full h-[44px]  flex items-center justify-center mx-[15px]"
            onClick={onCardClose}
          >
            <div className=" font-grotesk text-[14px] leading-[15.4px] font-semibold capitalize ">
              {t('MobileFilter.Apply')}
            </div>
            <Icon name="arrowLearnMoreBlack" className="ml-[11px]" />
          </Button>
        </div>
      </div>
    </>
  );
};

export { MobileFilters };
