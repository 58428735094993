import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getMarketplaceItems as getMarketplaceItemsApi,
  getMarketplaceFilters as getMarketplaceFiltersApi,
  MarketplaceParams,
  getMarketplaceItem as getMarketplaceItemApi,
} from 'api';
import { MarketplaceItem, MarketplaceItemDetailed } from './types';
import { store } from './configure-store';

const marketplaceItemType = {
  regular: 'regular',
  premium: 'premium',
  buffpayOnly: 'buff_pay',
};

const getMarketplaceItems = createAsyncThunk(
  'marketplace/items',
  async (
    params: MarketplaceParams & {
      isInitialLoading: boolean;
      shouldDisableLoading?: boolean;
    }
  ) => {
    const { isInitialLoading, shouldDisableLoading, ...resolvedParams } =
      params;
    const { config } = store.getState();
    const response = await getMarketplaceItemsApi(resolvedParams);
    return { ...response?.data, pageItemSize: config.pageItemSize };
  }
);

const getMarketplaceItem = createAsyncThunk(
  'marketplace/item',
  async (id: string) => {
    const response = await getMarketplaceItemApi(id);
    return response?.data;
  }
);

const getMarketplaceFilters = createAsyncThunk(
  'marketplace/filters',
  async () => {
    const response = await getMarketplaceFiltersApi();

    return response?.data;
  }
);

type MarketplaceState = {
  items: MarketplaceItem[];
  loading: boolean;
  loadingMore: boolean;
  loadingDetailedItem: boolean;
  shouldLoadMore: boolean;
  itemsOverlay: boolean;
  currentItem: MarketplaceItemDetailed | null;
  showMobileFilters: boolean;
  filters: {
    types: [];
    geo: [];
    categories: [];
    sorting: {};
  };
};

export const initialState: MarketplaceState = {
  items: [],
  loading: true,
  loadingMore: false,
  shouldLoadMore: true,
  loadingDetailedItem: false,
  currentItem: null,
  itemsOverlay: false,
  showMobileFilters: false,
  filters: {
    types: [],
    geo: [],
    categories: [],
    sorting: {},
  },
};

const marketplaceSlice = createSlice({
  name: 'marketplace',
  initialState,
  reducers: {
    changeShouldLoadMore: (state, action) => {
      state.shouldLoadMore = Boolean(action.payload);
    },

    resetCurrentItem: (state) => {
      state.currentItem = null;
      state.itemsOverlay = false;
    },
    toggleMobileFilters: (state) => {
      state.showMobileFilters = !state.showMobileFilters;
    },
    hideMobileFilters: (state) => {
      state.showMobileFilters = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMarketplaceItems.pending, (state, action) => {
      if (action.meta.arg.shouldDisableLoading) {
        return;
      }
      if (action.meta.arg.isInitialLoading) {
        state.loading = true;
      } else {
        state.loadingMore = true;
      }
    });
    builder.addCase(getMarketplaceItems.fulfilled, (state, action) => {
      if (action.meta.arg.isInitialLoading) {
        state.loading = false;
        state.items = action.payload.data;
        state.shouldLoadMore = true;
      } else {
        state.loadingMore = false;
        state.items.push(...action.payload.data);
      }

      if (
        action.payload.count < action.payload.pageItemSize ||
        action.payload.data?.length === 0
      ) {
        state.shouldLoadMore = false;
      }
    });
    builder.addCase(getMarketplaceItems.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getMarketplaceFilters.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getMarketplaceFilters.fulfilled, (state, action) => {
      state.loading = false;
      state.filters = action.payload.data;
    });

    builder.addCase(getMarketplaceItem.pending, (state, action) => {
      state.loadingDetailedItem = true;
    });
    builder.addCase(
      getMarketplaceItem.fulfilled,
      (state, { payload: { data } }) => {
        state.loadingDetailedItem = false;
        const original = data.item;
        const itemInMarketplaceArr = state.items.findIndex(
          (item) => item.id === original.id
        );
        const resolvedItem = {
          ...original,
          isOutOfStock: state.items[itemInMarketplaceArr].isOutOfStock,
          lastPurchaseDate: state.items[itemInMarketplaceArr].lastPurchaseDate,
        };
        if (itemInMarketplaceArr) {
          state.items.splice(itemInMarketplaceArr, 1, resolvedItem);
        }

        state.currentItem = resolvedItem;
        state.itemsOverlay = true;
      }
    );
    builder.addCase(getMarketplaceItem.rejected, (state, action) => {
      state.loadingDetailedItem = false;
    });
  },
});

const marketplaceReducer = marketplaceSlice.reducer;
export const {
  changeShouldLoadMore,
  resetCurrentItem,
  toggleMobileFilters,
  hideMobileFilters,
} = marketplaceSlice.actions;

export {
  getMarketplaceItems,
  getMarketplaceItem,
  getMarketplaceFilters,
  marketplaceReducer,
  marketplaceItemType,
};
