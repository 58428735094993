import { FC, PropsWithChildren } from 'react';
import c from 'classnames';

export type CardProps = {
  className?: string;
  contentClassName?: string;
  src?: string;
  alt?: string;
  onClick?: () => void;
};

const Card: FC<PropsWithChildren<CardProps>> = ({
  className,
  contentClassName,
  src,
  children,
  alt,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={c(
        'rounded-lg w-[168px] md:w-[241px] h-[264px] md:h-[300px] bg-dark-card flex flex-col cursor-pointer',
        className
      )}
    >
      <div className="h-[104px] md:h-[124px] w-full">
        {src ? (
          <img
            src={src}
            alt={alt}
            className="card-image h-full w-full object-cover rounded-t-lg"
          />
        ) : null}
      </div>
      <div
        className={c(
          'px-[12px] md:px-[15px] py-[10px] md:py-[12px] flex flex-col h-full',
          contentClassName
        )}
      >
        {children}
      </div>
    </div>
  );
};

export { Card };
