import { FC, InputHTMLAttributes, useState, useRef } from 'react';
import c from 'classnames';

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string | null;
  inputClassName?: string;
};

const Input: FC<InputProps> = ({
  className,
  label,
  value,
  inputClassName,
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  const ref = useRef<HTMLInputElement>(null);
  const onFocus = () => {
    setFocused(true);
  };
  const onBlur = () => {
    setFocused(false);
  };

  return (
    <div className={c('relative', className)}>
      <span
        className={c(
          'transition-all font-grotesk typography-card-label absolute top-[50%] translate-y-[-50%] left-[15px]',
          {
            'top-[25%] transition-all': focused || Boolean(value),
          }
        )}
        onClick={() => {
          ref.current && ref.current.focus();
        }}
      >
        {label}
      </span>
      <input
        {...props}
        // placeholder={label}
        className={c(
          'bg-neutral-40 py-[16px] px-[14px] font-bold font-grotesk typography-card-label rounded-[8px] border-neutral-30 border-[1px] focus-within:outline-none',
          {
            'pt-[26px] pb-[6px]': focused || Boolean(value),
          },
          inputClassName
        )}
        ref={ref}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  );
};

export { Input };
