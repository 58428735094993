import { FC } from 'react';
import c from 'classnames';
import { Icon } from 'components';
import { MarketplaceFilters } from '../use-marketplace-filters';
import { maxItemToShow } from './filters-card';

export type ActiveFiltersMenuProps = {
  isOpened: boolean;
  closeMenu: () => void;
  activeFilters: MarketplaceFilters['activeFilters'];
  resetOneFilter: MarketplaceFilters['resetOneFilter'];
};

const ActiveFiltersMenu: FC<ActiveFiltersMenuProps> = ({
  isOpened,
  closeMenu,
  activeFilters,
  resetOneFilter,
}) => {
  const items = activeFilters.slice(maxItemToShow);
  return isOpened ? (
    <>
      <div
        className="absolute w-full h-full top-0 right-0 bg-neutral-60-opacity"
        onClick={closeMenu}
      ></div>
      <div className="absolute bottom-[50px] right-[35%] bg-neutral-30 rounded-[8px] min-w-[175px]">
        {items.map((filter, index) => (
          <div
            className={c(
              'flex justify-between py-[12px] px-[15px] hover:font-bold hover:bg-neutral-60',
              {
                'rounded-t-[8px]': index === 0,
                'rounded-b-[8px]': index === items.length - 1,
              }
            )}
            key={filter.value}
          >
            <p className="font-grotesk typography-card-label">{filter.value}</p>
            <Icon
              name="closeDropdown"
              className="cursor-pointer"
              onClick={() => {
                resetOneFilter(filter);
              }}
            />
          </div>
        ))}
      </div>
    </>
  ) : (
    <></>
  );
};

export { ActiveFiltersMenu };
