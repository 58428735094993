import { FC } from 'react';
import { SideActionCard, Spinner, Icon } from 'components';
import { useSelector } from 'react-redux';
import { marketplaceSelector, resetCurrentItem, useAppDispatch } from 'store';
import { useBuyItem } from './use-buy-item';

export const buyItemSteps = {
  stepOne: '1',
  stepTwo: '2',
  stepThree: '3',
};

export type BuyItemCardProps = {};

const BuyItemCard: FC<BuyItemCardProps> = () => {
  const { loadingDetailedItem, currentItem } = useSelector(marketplaceSelector);

  const { activeComponent } = useBuyItem();

  const dispatch = useAppDispatch();

  return (
    <SideActionCard className="w-[400px] mb-[10px] flex-1 flex flex-col relative max-h-[49.5%]">
      {loadingDetailedItem ? (
        <Spinner size={40} />
      ) : (
        <div className="h-full flex flex-col">
          <Icon
            onClick={() => {
              dispatch(resetCurrentItem());
            }}
            name="closeWhite"
            className="absolute top-[17px] right-[25px] cursor-pointer"
          />
          <h6 className="color-white mb-[16px]">{currentItem?.name}</h6>
          {activeComponent}
        </div>
      )}
    </SideActionCard>
  );
};

export { BuyItemCard };
