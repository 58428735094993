import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { marketplaceSelector, userSelector } from 'store';
import { postConfirmationCode, postBuyItem } from 'api';
import { ConfirmWithEmail } from './confirm-with-email';
import { PreviewItem } from './preview-item';
import { SuccessfulPurchase } from './successful-purchase';

export const buyItemSteps = {
  stepOne: '1',
  stepTwo: '2',
  stepThree: '3',
};

type BuyStepKeys = keyof typeof buyItemSteps;
type BuyStep = typeof buyItemSteps[BuyStepKeys];

export type UseBuyItem = () => {
  activeComponent?: JSX.Element;
  activeStep: BuyStep;
};

const useBuyItem: UseBuyItem = () => {
  const { currentItem } = useSelector(marketplaceSelector);
  const { balance, email, isPremium } = useSelector(userSelector);

  const [activeStep, setActiveStep] = useState<BuyStep>(buyItemSteps.stepOne);
  const [errorInCode, setErrorInCode] = useState(false);

  const goToFirstStep = () => {
    setActiveStep(buyItemSteps.stepOne);
    setErrorInCode(false);
  };

  useEffect(() => {
    goToFirstStep();
  }, [currentItem?.id]);

  const goToSecondStep = async () => {
    try {
      if (currentItem?.id) {
        await postConfirmationCode({ id: currentItem?.id });
        setActiveStep(buyItemSteps.stepTwo);
      }
    } catch (e) {}
  };

  const goToThirdStep = async (code: string) => {
    setErrorInCode(false);

    try {
      if (currentItem?.id) {
        await postBuyItem({
          itemId: currentItem?.id,
          code: Number(code),
        });
        setActiveStep(buyItemSteps.stepThree);
      }
    } catch (e) {
      setErrorInCode(true);
    }
  };

  const activeComponent = useMemo(() => {
    if (activeStep === buyItemSteps.stepOne) {
      return (
        <PreviewItem
          marketplaceItem={currentItem}
          balance={balance}
          goNext={goToSecondStep}
          isPremiumUser={isPremium}
        />
      );
    }

    if (activeStep === buyItemSteps.stepTwo) {
      return (
        <ConfirmWithEmail
          email={email}
          goBack={goToFirstStep}
          goNext={goToThirdStep}
          errorInCode={errorInCode}
        />
      );
    }

    if (activeStep === buyItemSteps.stepThree) {
      return <SuccessfulPurchase name={currentItem?.name} />;
    }
  }, [activeStep, currentItem, balance, email, errorInCode, isPremium]);

  return { activeComponent, activeStep };
};

export { useBuyItem };
