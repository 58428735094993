// We cannot access tailwind theme inside src so it is a copy of tailwind config theme
export const theme = {
  colors: {
    primary: '#C8FF88',
    dark: '#000',
    'dark-card': '#262626',
    'card-out-of-stock': '#060606B2',
    'gray-text': '#EAEAEA',
    'gray-label': '#A3A3A3',
    'gray-text-card': '#808080',
    'neutral-70': '#676767',
    'neutral-60': '#525252',
    'neutral-60-opacity': '#26262699',
    'neutral-50': '#2E2E2E',
    'neutral-40': '#383838',
    'neutral-30': '#434343',
    'gold-border': '#FFD17699',
    'gold-primary': '#FFD176',
    pink: '#FF83EB',
  },
};

export const gradientForCards =
  'linear-gradient(180deg, rgba(38, 38, 38, 0) 6.68%, #262626 100%)';
