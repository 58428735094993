import { FC } from 'react';
import { iconMapping } from './icon-mapping';

export type IconProps = {
  name: keyof typeof iconMapping;
  className?: string;
  onClick?: () => void;
};

const Icon: FC<IconProps> = ({ name, className, onClick }) => {
  return (
    <img
      src={iconMapping[name]}
      data-testid={name}
      alt={name}
      className={className}
      onClick={onClick}
    />
  );
};

export { Icon };
