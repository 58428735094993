import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { throttle } from 'throttle-debounce';
import {
  AutoSizer as _AutoSizer,
  AutoSizerProps,
  List as _List,
  ListProps,
  ListRowRenderer,
  InfiniteLoader as _InfiniteLoader,
  InfiniteLoaderProps,
} from 'react-virtualized';
import { Icon, MarketplaceCard, SideActionCard, Spinner } from 'components';
import { useWindowDimensions } from 'hooks';
import {
  changeShouldLoadMore,
  getMarketplaceFilters,
  getMarketplaceItems,
  MarketplaceItem,
  getMarketplaceItem,
  resetCurrentItem,
  marketplaceSelector,
  useAppDispatch,
  toggleMobileFilters,
  configSelector,
} from 'store';
import { scrollToTop, parseJSON } from 'utils';
import { breakpoints } from '../../../constants';
import { EmptyMarketplaceTab } from './empty-marketplace-tab';
import { FiltersCard } from './filters-card';
import { MarketplaceFilters } from './use-marketplace-filters';
import { BuyItemCard, BuyItemCardMobile } from './buy-item-card';
import MarketplaceRightBottomImage from 'assets/images/marketplace-right-bottom-card.svg';
import { MobileFilters } from './mobile-filters/mobile-filters';
import c from 'classnames';
import { t } from 'i18next';
import { ResetFilterButton } from './mobile-filters/reset-filter-button';

const List = _List as unknown as FC<ListProps>;
const AutoSizer = _AutoSizer as unknown as FC<AutoSizerProps>;
const InfiniteLoader = _InfiniteLoader as unknown as FC<InfiniteLoaderProps>;

export type MarketplaceTabProps = MarketplaceFilters & {};

const MarketplaceTab: FC<MarketplaceTabProps> = ({
  categories,
  geo,
  type,
  outOfStock,
  activeFilters,
  resetFilters,
  resetOneFilter,
  sorting,
}) => {
  const { loading, items, shouldLoadMore, currentItem, showMobileFilters } =
    useSelector(marketplaceSelector);
  const { itemsAsArray, itemsSrc, refreshRate, pageItemSize, pageCount } =
    useSelector(configSelector);

  const parsedItems = parseJSON(itemsSrc);
  const parsedItemsArr = parsedItems;

  const resolvedItems = itemsAsArray?.length
    ? itemsAsArray
    : parsedItemsArr?.length
    ? parsedItemsArr
    : items;
  const dispatch = useAppDispatch();
  const { width } = useWindowDimensions();

  const isMobile = width < breakpoints.md;

  const [currentPage, setCurrentPage] = useState(2);

  const rowRenderer: (itemsPerRow: number) => ListRowRenderer =
    (itemsPerRow) =>
    ({ index, key, style }) => {
      const _items = [];
      const fromIndex = index * itemsPerRow;
      const toIndex = Math.min(fromIndex + itemsPerRow, resolvedItems.length);

      const itemWrapper = (item: MarketplaceItem) => (
        <MarketplaceCard
          key={item.id}
          geo={item.geoGroup}
          type={item.type}
          price={item.price}
          premiumPrice={item.premiumPrice}
          name={item.name}
          image={item.img}
          lastPurchaseDate={item.lastPurchaseDate}
          isOutOfStock={item.isOutOfStock}
          onClick={handleClickOnCard}
          id={item.id}
          hasActiveCard={Boolean(currentItem)}
          activeCardId={currentItem?.id}
        />
      );

      for (let i = fromIndex; i < toIndex; i++) {
        _items.push(itemWrapper(resolvedItems[i]));
      }

      return (
        <div
          key={key}
          style={style}
          className={c(
            'grid w-full h-fit grid-cols-[repeat(auto-fill,_168px)] md:grid-cols-[repeat(auto-fill,_241px)] gap-[10px] justify-center',
            {
              'opacity-[20%]': isMobile && showMobileFilters,
            }
          )}
        >
          {_items}
        </div>
      );
    };

  const noRowsRenderer = () => {
    return (
      <EmptyMarketplaceTab
        isFiltering={isFiltering}
        onReset={resetFilters}
        showButton={!isMobile}
      />
    );
  };

  const getRowHeight = () => {
    return isMobile ? 274 : 310;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentPage <= 2) {
        dispatch(
          getMarketplaceItems({
            page: 1,
            category: categories.selected,
            regions: geo.selected,
            type: type.selected,
            onlyAvailableNow: outOfStock.checked,
            limit: pageItemSize * pageCount,
            isInitialLoading: true,
            sort: String(sorting.selected),
            shouldDisableLoading: true,
          })
        );
      } else {
        dispatch(
          getMarketplaceItems({
            page: 1,
            category: categories.selected,
            regions: geo.selected,
            type: type.selected,
            onlyAvailableNow: outOfStock.checked,
            limit: pageItemSize * currentPage * pageCount,
            isInitialLoading: true,
            sort: String(sorting.selected),
            shouldDisableLoading: true,
          })
        );
      }
    }, refreshRate * 1000);

    return () => clearInterval(interval);
  }, [currentPage]);

  const loadMoreData = throttle(
    Number(process.env.REACT_APP_THROTTLE_DELAY_IN_MS) || 1000,
    () => {
      if (shouldLoadMore) {
        dispatch(
          getMarketplaceItems({
            page: currentPage + 1,
            limit: pageItemSize * pageCount,
            category: categories.selected,
            regions: geo.selected,
            type: type.selected,
            onlyAvailableNow: outOfStock.checked,
            isInitialLoading: false,
            sort: String(sorting.selected),
          })
        );

        setCurrentPage((p) => p + 1);
      }
    },
    { noLeading: false, noTrailing: false }
  ) as unknown as () => Promise<void>;

  useEffect(() => {
    !resolvedItems.length &&
      !loading &&
      dispatch(
        getMarketplaceItems({
          page: 1,
          category: categories.selected,
          regions: geo.selected,
          type: type.selected,
          onlyAvailableNow: outOfStock.checked,
          limit: pageItemSize * pageCount,
          isInitialLoading: true,
          sort: String(sorting.selected),
        })
      );
    dispatch(getMarketplaceFilters());
    changeShouldLoadMore(true);
  }, []);

  useEffect(() => {
    scrollToTop();
    dispatch(
      getMarketplaceItems({
        page: 1,
        limit: pageItemSize * pageCount,
        category: categories.selected,
        regions: geo.selected,
        type: type.selected,
        onlyAvailableNow: outOfStock.checked,
        isInitialLoading: true,
        sort: String(sorting.selected),
      })
    );
    setCurrentPage(1);
    changeShouldLoadMore(true);
  }, [
    categories.selected.length,
    geo.selected.length,
    type.selected.length,
    outOfStock.checked,
    sorting.selected,
  ]);

  const handleClickOnCard = (id: string) => {
    if (currentItem?.id === id) {
      dispatch(resetCurrentItem());
    } else {
      dispatch(getMarketplaceItem(id));
    }
  };

  const isFiltering = Boolean(
    categories.selected?.length || geo.selected?.length || type.selected?.length
  );

  const handleToggleFilters = () => {
    dispatch(toggleMobileFilters());
  };

  return (
    <div className="pt-[9.5px] pb-[14px] flex h-full ">
      {loading ? (
        <Spinner />
      ) : (
        <div
          className={c(
            'flex h-full w-full mt-[30px] max-w-full md:max-w-[calc(100%-400px)]  md:pr-[8px]',
            {}
          )}
        >
          <InfiniteLoader
            isRowLoaded={() => loading}
            loadMoreRows={loadMoreData}
            // we do not know the total count of items from the api. so the logic of not performing the request is happening on our side
            rowCount={100000000000}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer>
                {({ width, height }: { width: number; height: number }) => {
                  const itemWidth = isMobile ? 168 : 241;
                  const itemsPerRow = Math.floor(width / itemWidth);
                  const rowCount = Math.ceil(
                    resolvedItems.length / itemsPerRow
                  );

                  return (
                    <>
                      {isMobile ? (
                        <div
                          className={c('w-full h-[44px] bg-black mb-[15px] ', {
                            'opacity-[20%]': isMobile && showMobileFilters,
                          })}
                        >
                          <div
                            className="w-full h-[44px] bg-neutral-50 rounded-[30px] absolute top-[0px] left-[0px] flex items-center justify-center z-[100] cursor-pointer"
                            onClick={handleToggleFilters}
                          >
                            {showMobileFilters || activeFilters.length !== 0 ? (
                              <Icon name="filterIconGreen" />
                            ) : (
                              <Icon name="filterIcon" />
                            )}

                            <div
                              className={c(
                                'ml-[8px] text-[16px] leading-[22px] font-bold cursor-pointer',
                                {
                                  'text-primary':
                                    showMobileFilters ||
                                    activeFilters.length !== 0,
                                }
                              )}
                            >
                              {t('MobileFilter.Heading')}
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <List
                        ref={registerChild}
                        onRowsRendered={onRowsRendered}
                        height={height}
                        noRowsRenderer={noRowsRenderer}
                        rowCount={rowCount}
                        rowHeight={getRowHeight}
                        rowRenderer={rowRenderer(itemsPerRow)}
                        width={width}
                      />
                    </>
                  );
                }}
              </AutoSizer>
            )}
          </InfiniteLoader>
        </div>
      )}
      {isMobile ? <MobileFilters isOpened={showMobileFilters} /> : null}
      {isFiltering && isMobile && !showMobileFilters ? (
        <ResetFilterButton onClick={resetFilters} />
      ) : null}
      <div className="max-h-[calc(100vh-70px)] h-full hidden md:flex md:flex-col fixed top-[36px] right-[10px] z-[50]">
        {currentItem ? (
          <BuyItemCard />
        ) : (
          <FiltersCard
            categories={categories}
            geo={geo}
            type={type}
            outOfStock={outOfStock}
            activeFilters={activeFilters}
            resetFilters={resetFilters}
            resetOneFilter={resetOneFilter}
          />
        )}
        <SideActionCard className="flex items-center justify-center flex-1 h-[50%] overflow-hidden w-[400px]">
          <img src={MarketplaceRightBottomImage} alt="Buff Promo" />
        </SideActionCard>
      </div>
      {isMobile ? <BuyItemCardMobile isOpened={Boolean(currentItem)} /> : null}
    </div>
  );
};

export { MarketplaceTab };
