import { FC } from 'react';
import { GiftCodeItem } from './gift-code-item';
import { t } from 'i18next';

export type GiftCodesTabProps = {
  codes?: string[];
};

const GiftCodesTab: FC<GiftCodesTabProps> = ({ codes = [] }) => {
  return (
    <div className="pt-[18px] px-[20px] w-[380px] h-full pb-[35px]">
      {codes.map((code) => (
        <GiftCodeItem
          key={code}
          title={t('InventoryCard.Description.GiftCode')}
          copiedTextAlert={t('InventoryCard.Description.CopiedCode')}
          className="mb-[10px]"
          giftCode={code}
        />
      ))}
    </div>
  );
};

export { GiftCodesTab };
