import { FC } from 'react';
import { Button, Icon } from 'components';
import { t } from 'i18next';

export type ResetFilterButtonProps = {
  onClick?: () => void;
};

const ResetFilterButton: FC<ResetFilterButtonProps> = ({ onClick }) => {
  return (
    <div className="w-full absolute bottom-[70px] h-[40px] flex items-center justify-center">
      <Button
        className="w-[200px] h-[40px] rounded-[50px] flex items-center justify-center z-[99]"
        onClick={onClick}
      >
        <Icon name="resetFiltersIcon" />
        <div className="ml-[10px] text-[14px] leading-[20px] font-grotesk font-semibold whitespace-nowrap">
          {t('MobileFilter.ResetFilter')}
        </div>
      </Button>
    </div>
  );
};

export { ResetFilterButton };
