import { FC } from 'react';
import { useSelector } from 'react-redux';
import c from 'classnames';
import { useSwipeable } from 'react-swipeable';
import { useAppDispatch, marketplaceSelector, resetCurrentItem } from 'store';
import { useSwipeAnimationMobile } from 'hooks';
import { buyItemSteps } from './buy-item-card';
import { useBuyItem } from './use-buy-item';

export type BuyItemCardMobileProps = {
  isOpened?: boolean;
};

const BuyItemCardMobile: FC<BuyItemCardMobileProps> = ({ isOpened }) => {
  const { currentItem } = useSelector(marketplaceSelector);

  const { activeComponent, activeStep } = useBuyItem();

  const dispatch = useAppDispatch();

  const { onCardClose, styles, isCardOpened } = useSwipeAnimationMobile({
    onClose: () => {
      dispatch(resetCurrentItem());
    },
    isOpened,
  });

  const handler = useSwipeable({
    onSwipedDown: onCardClose,
  });

  return (
    <>
      {isCardOpened ? (
        <div
          className="fixed top-0 left-0 h-screen w-screen bg-overlay-bg z-[100]"
          onClick={onCardClose}
        />
      ) : null}
      <div
        className={c(
          'bg-dark-card absolute left-0 bottom-0 w-full overflow-y-scroll overflow-x-hidden z-[101] transition-all',
          {
            [`min-h-[${activeStep === buyItemSteps.stepOne ? 726 : 350}px]`]:
              true,
            ...styles,
            hidden: !isOpened,
          }
        )}
      >
        <div
          className="w-full h-[14px] flex items-end justify-center "
          onClick={onCardClose}
          {...handler}
        >
          <div className="w-[80px] h-[4px] bg-neutral-70 rounded-[4px]"></div>
        </div>
        {activeStep !== buyItemSteps.stepThree ? (
          <h3 className="mt-[20px] mb-[14px] px-[15px] font-railroad text-[20px] font-bold capitalize leading-[26px]">
            {currentItem?.name}
          </h3>
        ) : null}
        {activeComponent}
      </div>
    </>
  );
};

export { BuyItemCardMobile };
