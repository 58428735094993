import { FC, useMemo } from 'react';
import ReactSelect, { MultiValue, SingleValue } from 'react-select';
import c from 'classnames';
import { theme } from 'constants/theme';
import { Icon } from '../icon';
import './select.css';

export type Option = {
  value: number | string;
  label: number | string;
};

export type SelectProps = {
  options: Option[];
  styles?: Record<string, any>;
  containerClassName?: string;
  isMulti?: boolean;
  placeholder?: string | null;
  selectedOptions?: Option['value'][];
  setSelectedOptions?: (options: Option['value'][]) => void;
  allItemsLabel?: string | null;
};

const makeStyles = (styles: Record<string, any> = {}) => ({
  ...styles,
  valueContainer: (provided: Record<string, any>) => ({
    ...provided,
    padding: '17px 15px',
    ...styles.valueContainer,
  }),
  control: () => ({
    border: '1px solid',
    borderColor: theme.colors['neutral-30'],
    background: theme.colors['neutral-40'],
    color: '#fff',
    borderRadius: '8px',
    display: 'flex',
    cursor: 'pointer',
    fontSize: '12px',
    transition: '0.3s',
    height: '50px',
    '&:hover': {
      border: '1px solid',
      borderColor: theme.colors['neutral-70'],
    },
    ...styles.control,
  }),
  menu: () => ({
    background: '#383838',
    borderRadius: '8px',
    marginTop: '10px',
    overflowY: 'scroll',
    maxHeight: '300px',
    ...styles.menu,
  }),
  option: () => ({
    background: theme.colors['neutral-40'],
    color: theme.colors['gray-text'],
    cursor: 'pointer',
    padding: '12px 15px',
    fontSize: '12px',
    transition: '0.3s',
    '&:hover': {
      fontWeight: 700,
      background: theme.colors['neutral-60'],
    },
    ...styles.option,
  }),
  indicatorSeparator: () => ({
    display: 'none',
    ...styles.indicatorSeparator,
  }),
  placeholder: (provided: Record<string, any>) => ({
    ...provided,
    color: theme.colors['gray-text'],
    cursor: 'pointer',
    fontSize: '12px',
    ...styles.placeholder,
  }),
});

const selectAllItemValue = 'select-all-value';

const Select: FC<SelectProps> = ({
  options,
  styles,
  containerClassName,
  isMulti,
  placeholder,
  selectedOptions,
  setSelectedOptions = () => undefined,
  allItemsLabel,
  ...props
}) => {
  const resolvedStyles = useMemo(() => makeStyles(styles), [styles]);

  const handleChange = (
    optionsOnChange: MultiValue<Option> | SingleValue<Option>
  ) => {
    const currentOptions = Array.isArray(optionsOnChange)
      ? [...new Set((optionsOnChange || []).map((o) => o?.value || o))]
      : optionsOnChange;

    if (
      Array.isArray(currentOptions) &&
      currentOptions.includes(selectAllItemValue)
    ) {
      if (options?.length <= currentOptions?.length) {
        setSelectedOptions([]);
      } else {
        setSelectedOptions(options.map((o) => o.value));
      }
      return;
    }
    setSelectedOptions(currentOptions as (string | number)[]);
  };

  const handleFormat = (currentOption: Option) => {
    const isSelected =
      selectedOptions?.find((option) => option === currentOption.value) ||
      (currentOption.value === selectAllItemValue &&
        selectedOptions?.length === options.length);
    return (
      <div
        className={c('flex justify-between', {
          'font-bold': Boolean(isSelected),
        })}
      >
        <p>{currentOption?.label}</p>
        {isMulti ? (
          <Icon name={Boolean(isSelected) ? 'checked' : 'unchecked'} />
        ) : null}
      </div>
    );
  };

  const resolvedOptions = useMemo(
    () =>
      allItemsLabel
        ? [{ label: allItemsLabel, value: selectAllItemValue }, ...options]
        : options,
    [allItemsLabel, options]
  );

  return (
    <div className={c('font-grotesk', containerClassName)}>
      <ReactSelect
        placeholder={placeholder}
        isMulti={isMulti}
        isClearable={false}
        closeMenuOnSelect={!isMulti}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        defaultValue={selectedOptions?.map((o) => ({ label: o, value: o }))}
        onChange={handleChange}
        formatOptionLabel={handleFormat}
        styles={resolvedStyles}
        options={resolvedOptions}
        classNamePrefix="react-select"
        menuPosition="fixed"
        isSearchable={false}
        {...props}
      />
    </div>
  );
};

export { Select };
