import { FC } from 'react';
import c from 'classnames';
import { Icon, iconMappingFlags, IconProps } from '../icon';
import { useTranslation } from 'react-i18next';
import { marketplaceFilters } from 'constants/marketplace';

const keys = Object.keys(iconMappingFlags);

export type GeoLabelProps = {
  geo?: string | null;
  className?: string;
  spanClassName?: string;
};

const GeoLabel: FC<GeoLabelProps> = ({ className, geo, spanClassName }) => {
  const { t } = useTranslation();
  const isGlobal = geo && geo.toLowerCase() === marketplaceFilters.global;
  const icon = keys.find((key) => key.toLowerCase() === geo?.toLowerCase());

  return (
    <div className={c('flex items-center', className)}>
      {isGlobal ? (
        <Icon name="planet" className="mr-[8px]" />
      ) : icon ? (
        <Icon name={icon as IconProps['name']} className="mr-[8px]" />
      ) : null}
      <span
        className={c(
          'text-primary uppercase typography-basic font-bold',
          {
            'mr-[8px]': isGlobal || geo,
          },
          spanClassName
        )}
      >
        {isGlobal ? t('MarketplaceCard.Global') : geo}
      </span>
    </div>
  );
};

export { GeoLabel };
