import { FC, useState, useMemo } from 'react';
import c from 'classnames';
import { useSwipeable } from 'react-swipeable';
import { useAppDispatch } from 'store';
import { resetCurrentItemInventory } from 'store/inventory-slice';
import { InventoryItem } from 'store/types/inventory';
import { GiftCodeItem } from './gift-code-item';
import { BUFF_PAY, PREMIUM } from 'constants/inventory';
import { t } from 'i18next';
import { parseByNewLines, parseCode } from 'utils';
import { useSwipeAnimationMobile, useWindowDimensions } from 'hooks';

export type InventoryDetailedCardMobileProps = {
  item?: InventoryItem;
  isOpened?: boolean;
};

const InventoryDetailedCardMobile: FC<InventoryDetailedCardMobileProps> = ({
  item = { marketplaceItem: {} },
  isOpened,
}) => {
  const [isOpenRedeem, setIsOpenRedeem] = useState(false);

  const { height } = useWindowDimensions();
  const lowHeightTrigger = height < 720;

  const { onCardClose, styles, isCardOpened } = useSwipeAnimationMobile({
    onClose: () => {
      dispatch(resetCurrentItemInventory());
    },
    isOpened,
  });

  const { name, imageUrl, description } = item;
  const { howToRedeem } = item.marketplaceItem;
  const type = item.marketplaceItem.type;
  const giftCode = item.marketplaceItem.giftCode;

  const parsedCodes = parseCode(giftCode);

  const handleRedeemTab = () => {
    setIsOpenRedeem((prevValue) => !prevValue);
  };

  const inventoryType = useMemo(() => {
    if (type === BUFF_PAY) {
      return { text: t('InventoryCard.Buffpay') };
    }
    if (type === PREMIUM) {
      return { text: t('InventoryCard.Premium') };
    }
  }, [type, t]);

  const dispatch = useAppDispatch();

  const handler = useSwipeable({
    onSwipedDown: onCardClose,
  });

  return (
    <>
      {isCardOpened ? (
        <div
          className={c(
            'fixed top-0 left-0 h-screen w-screen bg-overlay-bg z-[100]'
          )}
          onClick={onCardClose}
        />
      ) : null}
      <div
        className={c(
          'bg-dark-card absolute left-0 top-[94px] min-h-[716px] w-full h-[calc(100%-94px)] overflow-y-scroll overflow-x-hidden z-[101] transition-all',
          styles,
          { hidden: !isOpened, 'top-[40px] min-h-[680px]': lowHeightTrigger }
        )}
      >
        <div
          className="w-full h-[14px] flex items-end justify-center "
          onClick={onCardClose}
          {...handler}
        >
          <div className="w-[80px] h-[4px] bg-neutral-70 rounded-[4px]"></div>
        </div>
        <h3 className="mt-[20px] mb-[14px] px-[15px] font-railroad text-[20px] font-bold capitalize leading-[26px]">
          {name}
        </h3>
        <div className="mx-[15px] h-[214px] rounded-[8px]">
          <img
            src={imageUrl}
            alt={name}
            className="w-full h-full object-cover rounded-[8px]"
          />
        </div>
        {type ? (
          <h3
            className={c(
              'mt-[16px] mx-[15px] font-railroad text-[16px] leading-[22px] font-bold capitalize',
              {
                'text-gray-text': type === BUFF_PAY,
                'text-gold-primary': type === PREMIUM,
              }
            )}
          >
            {inventoryType?.text}
          </h3>
        ) : null}

        <div className="mt-[14px] mx-[15px] max-h-[108px] overflow-auto">
          {parseByNewLines({
            str: description,
            classNameForSpan:
              'font-grotesk text-gray-text-card text-[14px] leading-[18px] font-semibold',
          })}
        </div>
        {Boolean(howToRedeem) ? (
          <div className="mt-[36px] mx-[15px] h-[32px] flex items-center justify-between ">
            <div className="flex items-center justify-start font-grotesk text-[14px] capitalize font-semibold ">
              {t('Tab.InventoryCardDetails.HowToRedeem.Mobile')}
            </div>
            <button
              className={c(
                'h-full w-[90px] flex items-center justify-center border-[1px] rounded-[39px] text-[14px] font-semibold font-grotesk leading-[20px] capitalize text-white',
                { 'bg-white text-toggle-button': isOpenRedeem }
              )}
              onClick={handleRedeemTab}
            >
              {isOpenRedeem ? 'hide' : 'expand'}
            </button>
          </div>
        ) : null}
        {isOpenRedeem ? (
          <div className="min-h-[15px] mx-[15px] mt-[15px]">
            {parseByNewLines({
              str: howToRedeem,
              classNameForSpan:
                'font-grotesk text-[14px] font-semibold leading-[18px] text-gray-text-card',
            })}
          </div>
        ) : null}
        {Boolean(howToRedeem) ? (
          <div className="mt-[15px] mx-[15px] w-[95%] h-[1px] bg-neutral-30"></div>
        ) : null}
        <h3 className="mt-[20px] mb-[15px] ml-[15px] capitalize text-railroad text-[16px] leading-[22px] font-bold text-gray-text">
          {t('Tab.InventoryCardDetails.Codes.Mobile')}
        </h3>
        <div className="mx-[15px] mb-[22px]">
          {parsedCodes?.length
            ? parsedCodes.map((code) => (
                <GiftCodeItem
                key={code}
                  copiedTextAlert="Copied"
                  title="gift-code"
                  className="mb-[10px] !w-full"
                  giftCode={code}
                />
              ))
            : null}
        </div>
      </div>
    </>
  );
};

export { InventoryDetailedCardMobile };
