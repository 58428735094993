import { FC } from 'react';
import { Button, Icon } from 'components/common';
import EmptyPlaceholder from 'assets/images/ghost.png';
import { useTranslation } from 'react-i18next';
import c from 'classnames';

export type EmptyInventoryTabProps = {
  goToMarketplaceTab: () => void;
};

const EmptyInventoryTab: FC<EmptyInventoryTabProps> = ({
  goToMarketplaceTab,
}) => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-full flex items-center justify-center flex-col">
      <img src={EmptyPlaceholder} alt="Ghost" />
      <h5 className="uppercase font-bold typography-card-title md:leading-[26px]">
        {t('Tabs.Inventory.Empty.noInventoryItems.Heading')}
      </h5>
      <p className="font-grotesk text-gray-text-card mt-[16px] mb-[21px] font-semibold text-[14px] md:leading-[20px] text-grotesk">
        {t('Tabs.Inventory.Empty.noInventoryItems.subHeading')}
      </p>
      <Button className={c('text-grotesk')} onClick={goToMarketplaceTab}>
        <span className="block font-grotesk capitalize font-semibold text-[14px]">
          {t('Tabs.Inventory.Empty.noInventoryItems.ButtonLabel')}
        </span>
        <Icon name="arrowLearnMoreBlack" className="ml-[11px]" />
      </Button>
    </div>
  );
};

export { EmptyInventoryTab };
