import { FC } from 'react';
import { Select } from 'components';
import { theme } from 'constants/theme';
import { MarketplaceFilters } from './use-marketplace-filters';
import {
  defaultSortingLabel,
  defaultSortingValue,
} from 'constants/marketplace';
import { useTranslation } from 'react-i18next';

const styles = {
  control: {
    height: '32px',
    width: '190px',
  },
  valueContainer: {
    padding: '4px 15px',
  },
  menu: {
    border: '1px solid',
    borderColor: theme.colors['neutral-70'],
  },
  option: {
    color: theme.colors['gray-text-card'],
    '&:hover': {
      color: theme.colors['gray-text'],
      fontWeight: 'normal',
      background: theme.colors['neutral-60'],
    },
  },
};

export type SortingProps = {
  shouldBeShown: boolean;
  values: MarketplaceFilters;
};

const Sorting: FC<SortingProps> = ({ shouldBeShown, values }) => {
  const { t } = useTranslation();
  const placeholder = values.sorting.options.find(
    (option) => option.value === values.sorting.selected
  )?.label;

  return shouldBeShown ? (
    <div className="hidden md:flex items-center bg-black pl-[21px] min-w-[280px] mr-[10px] pb-[3px]">
      <p className="mr-[10px]">{t('Tabs.Marketplace.Sorting')}</p>
      <Select
        options={values.sorting.options}
        setSelectedOptions={values.sorting.onChange}
        selectedOptions={[values.sorting.selected || defaultSortingValue]}
        styles={styles}
        placeholder={placeholder ? String(placeholder) : t(defaultSortingLabel)}
      />
    </div>
  ) : (
    <></>
  );
};

export { Sorting };
