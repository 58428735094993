import { api } from './axios-client';
import { urls } from './constants';

export type InventoryParams = {
  page: number;
  limit?: number;
};
const getInventoryItems = async (params: InventoryParams) => {
  const response = await api.get(urls.inventory, {
    params,
  });

  return response;
};

export { getInventoryItems };
