import { Icon } from 'components';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Success from 'assets/images/success-mobile-marketplace.png';
import { useWindowDimensions } from 'hooks';
import { breakpoints } from 'constants/breakpoints';
import { useAppDispatch, getUser } from 'store';

export type SuccessfulPurchaseProps = {
  name?: string;
};

const SuccessfulPurchase: FC<SuccessfulPurchaseProps> = ({ name }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const isMobile = width < breakpoints.md;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUser());
  }, []);

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      {isMobile ? (
        <img src={Success} alt="Success" className="mt-[48px]" />
      ) : (
        <Icon name="checkmark" />
      )}
      <p className="mt-[20px] mb-[16px] text-white typography-card-title">
        {name}
      </p>
      <p className="font-grotesk text-center max-w-[230px] font-bold text-[14px] leading-[18px] mb-[70px] md:mb-0 md:typography-basic text-gray-text-card">
        {t('BuyItemCard.Successful')}
      </p>
    </div>
  );
};

export { SuccessfulPurchase };
