import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { Icon } from '../icon';
import { FilterType } from 'pages/main-page/marketplace-tab/use-marketplace-filters';
import { marketplaceFilters } from 'constants/marketplace';

export type LabelProps = {
  className?: string;
  text: string;
  onClick?: (text: string) => void;
  showMore?: boolean;
  type?: FilterType;
  isActive?: boolean;
};

const Label: FC<LabelProps> = ({
  text,
  className,
  onClick,
  showMore,
  type,
  isActive,
}) => {
  const { t } = useTranslation();
  const resolvedText = useMemo(
    () =>
      type !== 'geo'
        ? text.toLowerCase()
        : t('FiltersCard.geoFilterLabel', {
            geo: marketplaceFilters.global === text ? text.toLowerCase() : text,
          }),
    [text, type, t]
  );
  return (
    <div
      onClick={() => {
        onClick && showMore && onClick(text);
      }}
      className={c(
        'flex px-[10px] py-[3px] rounded-[14px] border-neutral-50 border-[1px]',
        {
          'bg-primary': isActive,
          'bg-neutral-60': !isActive,
        },
        className
      )}
    >
      <p
        className={c(
          'font-grotesk capitalize mr-[4px] typography-card-label font-semibold',
          {
            'text-primary cursor-pointer': showMore,
            'text-gray-label': !showMore,
            'text-dark-card': isActive,
          }
        )}
      >
        {resolvedText}
      </p>
      {showMore ? null : (
        <Icon
          name="close"
          className="cursor-pointer"
          onClick={() => {
            onClick && onClick(text);
          }}
        />
      )}
    </div>
  );
};

export { Label };
