import { FC } from 'react';
import c from 'classnames';
import { SpinnerCircular } from 'spinners-react';

const spinnerColor = '#C8FF88';

export type SpinnerProps = {
  className?: string;
  size?: number;
};

const Spinner: FC<SpinnerProps> = ({ className, size = 80 }) => {
  return (
    <div
      data-testid="spinner"
      className={c(
        'w-full h-full flex items-center justify-center md:mr-[420px]',
        className
      )}
    >
      <SpinnerCircular color={spinnerColor} size={size} />
    </div>
  );
};

export { Spinner };
