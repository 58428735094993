import { FC, useMemo } from 'react';
import { Button, Icon } from 'components/common';
import EmptyPlaceholder from 'assets/images/ghost.png';
import { useTranslation } from 'react-i18next';

export type EmptyMarketplaceTabProps = {
  isFiltering?: boolean;
  onReset?: () => void;
  showButton?: boolean;
};

const EmptyMarketplaceTab: FC<EmptyMarketplaceTabProps> = ({
  isFiltering,
  onReset,
  showButton,
}) => {
  const { t } = useTranslation();

  const params = useMemo(() => {
    if (isFiltering) {
      return {
        heading: t('Tabs.Marketplace.Empty.noItemsFilters.Heading'),
        subHeading: t('Tabs.Marketplace.Empty.noItemsFilters.Subheading'),
        showButton: showButton,
      };
    }

    return {
      heading: t('Tabs.Marketplace.Empty.noItems.Heading'),
      subHeading: undefined,
      showButton: false,
    };
  }, [isFiltering, t]);

  return (
    <div className="w-full h-full flex items-center justify-center flex-col md:mr-[410px]">
      <img src={EmptyPlaceholder} alt="Ghost" />
      <h5 className="uppercase font-bold typography-empty-title text-white">
        {params.heading}
      </h5>
      <p className="font-grotesk text-gray-text-card mt-[16px] mb-[21px] font-semibold">
        {params.subHeading}
      </p>
      {params.showButton ? (
        <Button onClick={onReset}>
          {t('Tabs.Marketplace.Empty.noItemsFilters.ButtonLabel')}{' '}
          <Icon name="arrowLearnMoreBlack" className="ml-[11px]" />
        </Button>
      ) : null}
    </div>
  );
};

export { EmptyMarketplaceTab };
