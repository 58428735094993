import axios from 'axios';
import { urls } from './constants';
import { store } from 'store';
// import { signIn } from './auth';

const api = axios.create({
  baseURL: urls.baseUrl,
});

api.interceptors.request.use(async (config) => {
  const token = store.getState().user.token;

  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${token}`,
  };
  return config;
});

// const timesToReconnect = 10;
// let countOfRequests = 0;

// api.interceptors.response.use(
//   async (config) => config,
//   async (err) => {
//     const originalConfig = err.config;
//
//     if (err.response.status === 401) {
//       originalConfig._retry = true;
//       countOfRequests += 1;
//       if (countOfRequests < timesToReconnect) {
//         try {
//           await signIn({ email: "sddas@mail.ru", password: "dasds", token: "sdad" });
//
//           return api(originalConfig);
//         } catch (_error) {
//           return Promise.reject(_error);
//         }
//       }
//     } else {
//       throw new Error(err);
//     }
//   }
// );

export { api };
