import { FC, useMemo } from 'react';
import { Card, CardProps } from '../common/card/card';
import { Button } from '../common/button/button';
import { Icon } from 'components/common';
import { GeoLabel } from 'components/common/geo-label';
import { inventoryItemCategory } from 'store/inventory-slice';
import c from 'classnames';
import { t } from 'i18next';
import { marketplaceItemType } from 'store';
import { InventoryItem } from 'store/types/inventory';
import dayjs from 'dayjs';

export type InventoryCardProps = Omit<CardProps, 'onClick'> & {
  name: string;
  type?: string | null;
  geo?: string | null;
  purchaseDate?: string;
  image?: string;
  className?: string;
  category?: string | null;
  item: InventoryItem;
  currentItemId?: InventoryItem['id'];
  onClick?: (item: InventoryItem) => void;
};

const InventoryCard: FC<InventoryCardProps> = ({
  name,
  item,
  type,
  purchaseDate,
  image,
  geo,
  className,
  category,
  onClick,
  currentItemId,
  ...props
}) => {
  const convertedDate = dayjs(purchaseDate).format('MMMM DD, YYYY');

  const isPremium =
    item.marketplaceItem.type &&
    item.marketplaceItem.type.toLocaleLowerCase() ===
      marketplaceItemType.premium.toLowerCase();

  const isBuffpay =
    item.marketplaceItem.type &&
    item.marketplaceItem.type.toLocaleLowerCase() ===
      marketplaceItemType.buffpayOnly.toLowerCase();

  const inventoryType = useMemo(() => {
    if (isBuffpay) {
      return { text: t('InventoryCard.Buffpay') };
    }

    if (isPremium) {
      return { text: t('InventoryCard.Premium') };
    }
  }, [type, t, isBuffpay, isPremium]);

  const isRafflePrize =
    category &&
    category.toLowerCase() === inventoryItemCategory.rafflePrize.toLowerCase();

  const inventoryCategory = useMemo(() => {
    if (isRafflePrize) {
      return { text: t('InventoryCard.RafflePrize') };
    }
  }, [type, t, isRafflePrize]);

  return (
    <Card
      {...props}
      onClick={() => {
        onClick && onClick(item);
      }}
      className={c('m-[4.5px] p-[1px] relative', {
        'border-primary border-[1px] p-[0px]': currentItemId === item.id,
      })}
      src={image}
      contentClassName={c(
        'px-[12px] md:px-[15px] pb-[12px] md:pb-[13px] pt-[6px] md:pt-[12px] flex flex-col h-full'
      )}
    >
      {Boolean(currentItemId && currentItemId !== item.id) ? (
        <div className="absolute top-0 right-0 w-full h-full bg-overlay-bg-desktop rounded-lg"></div>
      ) : null}
      <div className="flex items-center mb-[6px] md:mb-[10px] mr-[8px]">
        <div className="flex items-center text-railroad">
          <GeoLabel geo={geo} />
        </div>
        {inventoryType ? (
          <div className="flex items-center text-railroad">
            <span
              className={c('ml-[0px]  typography-basic font-bold', {
                'text-gray-text': isBuffpay,
                'text-gold-primary': isPremium,
              })}
            >
              {inventoryType.text}
            </span>
          </div>
        ) : null}

        {inventoryCategory ? (
          <div className="flex items-center text-railroad">
            <span
              className={c('ml-[17px]  typography-basic font-bold', {
                'text-primary': isRafflePrize,
              })}
            >
              {inventoryCategory.text}
            </span>
          </div>
        ) : null}
      </div>
      <h3 className="typography-cart-title font-bold marketplace-cart-title truncate mb-[8px] md:mb-[6px] text-railroad text-[16px] md:text-[18px]  leading-[22px] md:leading-[26px]">
        {name}
      </h3>
      <p className="mb-[0px] md:mb-[27px] font-semibold text-[12px] flex items-center font-grotesk text-gray-text-card  leading-[18px]  md:leading-[16px] ">
        {t('InventoryCard.DateOfPurchase')} {convertedDate}
      </p>
      <Button
        className={c(
          'w-[144px] h-[32px] md:w-[211px] md:h-[46px] text-[14px]  pl-[0px] pr-[0px] md:px-[0px]  text-semibold mt-auto'
        )}
      >
        <span className="block  font-grotesk capitalize md:text-[14px] font-semibold   md:font-semibold  leading-[16px] md:leading-[20px]">
          {t('InventoryCard.CheckDetails')}
        </span>
        <Icon name="arrowLearnMoreBlack" className="ml-[11px]  " />
      </Button>
    </Card>
  );
};

export { InventoryCard };
