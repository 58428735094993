import { Provider } from 'react-redux';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { store } from './store';
import { MainPage, TestIframe } from './pages';

const shouldIframeBeShown = process.env.REACT_APP_SHOULD_SHOW_IFRAME;
const shouldIframeBeShownResolved = Boolean(
  shouldIframeBeShown && shouldIframeBeShown !== 'false'
);

const App = () => {
  return (
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <Routes>
          {shouldIframeBeShownResolved ? (
            <Route path="/iframe" element={<TestIframe />} />
          ) : null}
          <Route
            path="/"
            element={
              <Provider store={store}>
                <MainPage />
              </Provider>
            }
          />
        </Routes>
      </QueryParamProvider>
    </BrowserRouter>
  );
};

export { App };
