import { api } from './axios-client';
import { urls } from './constants';

const getUser = async () => {
  const response = await api.get(urls.user);

  return response;
};

export { getUser };
