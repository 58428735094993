export const parseJSON = (str?: string) => {
  if (!str) return;

  try {
    const result = JSON.parse(str);

    return result;
  } catch (e) {
    console.log(e);

    return;
  }
};
