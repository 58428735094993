import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { initConfig } from 'api';

const getConfig = createAsyncThunk('config/getConfig', async () => {
  const response = await initConfig();

  return response;
});

export const configInitialState = {
  /* define the time that the items is valid, once we reach this time we should
  perform a call to cache/api and perform a fresh call */
  refreshRate: 60 * 30, // 30 minutes
  /* how many page will invoke in single request */
  pageCount: 1,
  /* how many item per page or per display-unit */
  pageItemSize: 20,
  /* The origin url for each item in store in his location in CDN, should manage as cascade by assets and theme */
  cdnBase: '',
  /* define any CORS setting to internal server usage */
  cors: '',
  /* load the items as array [Object], should override the internal API */
  itemsAsArray: [],
  /* load items as an JSON object, should override the internal API */
  itemsSrc: '',
};

const configSlice = createSlice({
  name: 'config',
  initialState: configInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getConfig.fulfilled, (state, { payload }) => {
      if (payload.Refrash_rate) {
        state.refreshRate = payload.Refrash_rate;
      }

      if (payload.Page_count) {
        state.pageCount = payload.Page_count;
      }

      if (payload.Page_item_size) {
        state.pageItemSize = payload.Page_item_size;
      }

      if (payload.CDN_base) {
        state.cdnBase = payload.CDN_base;
      }

      if (payload.CORS) {
        state.cors = payload.CORS;
      }

      if (payload.Items_as_array) {
        state.itemsAsArray = payload.Items_as_array;
      }

      if (payload.Items_src) {
        state.itemsSrc = payload.Items_src;
      }
    });
  },
});

const configReducer = configSlice.reducer;

export { configReducer, getConfig };
