import planetIcon from '../../../assets/icons/planet.svg';
import arrowLearnMoreIcon from '../../../assets/icons/arrow-learn-more.svg';
import arrowLearnMoreBlackIcon from '../../../assets/icons/arrow-learn-more-black.svg';
import arrowLearnMoreWhiteIcon from '../../../assets/icons/arrow-learn-more-white.svg';
import premiumPriceIcon from '../../../assets/icons/premium-price.svg';
import regularPriceIcon from '../../../assets/icons/regular-price.svg';
import checkedIcon from '../../../assets/icons/checked.svg';
import uncheckedIcon from '../../../assets/icons/unchecked.svg';
import closeIcon from '../../../assets/icons/close.svg';
import closeDropdownIcon from '../../../assets/icons/close-dropdown.svg';
import closeWhiteIcon from '../../../assets/icons/close-white.svg';
import coinIcon from '../../../assets/icons/coin.svg';
import checkmarkIcon from '../../../assets/icons/check-mark.svg';
import copyIcon from '../../../assets/icons/copy-icon.svg';
import copiedIcon from '../../../assets/icons/copied.svg';
import filterIcon from '../../../assets/icons/filter.svg';
import filterIconGreen from '../../../assets/icons/filterActive.svg';
import resetFiltersIcon from '../../../assets/icons/reset-filters.svg';

import AG from '../../../assets/flag-icons/AG.svg';
import AU from '../../../assets/flag-icons/AU.svg';
import BE from '../../../assets/flag-icons/BE.svg';
import BZ from '../../../assets/flag-icons/BZ.svg';
import CA from '../../../assets/flag-icons/CA.svg';
import CH from '../../../assets/flag-icons/CH.svg';
import CL from '../../../assets/flag-icons/CL.svg';
import CO from '../../../assets/flag-icons/CO.svg';
import CZ from '../../../assets/flag-icons/CZ.svg';
import DE from '../../../assets/flag-icons/DE.svg';
import DK from '../../../assets/flag-icons/DK.svg';
import ES from '../../../assets/flag-icons/ES.svg';
import EU from '../../../assets/flag-icons/EU.svg';
import FR from '../../../assets/flag-icons/FR.svg';
import GB from '../../../assets/flag-icons/GB.svg';
import GR from '../../../assets/flag-icons/GR.svg';
import IE from '../../../assets/flag-icons/IE.svg';
import IN from '../../../assets/flag-icons/IN.svg';
import IT from '../../../assets/flag-icons/IT.svg';
import JP from '../../../assets/flag-icons/JP.svg';
import KR from '../../../assets/flag-icons/KR.svg';
import MX from '../../../assets/flag-icons/MX.svg';
import NL from '../../../assets/flag-icons/NL.svg';
import NO from '../../../assets/flag-icons/NO.svg';
import NZ from '../../../assets/flag-icons/NZ.svg';
import PL from '../../../assets/flag-icons/PL.svg';
import SW from '../../../assets/flag-icons/SW.svg';
import TR from '../../../assets/flag-icons/TR.svg';
import UA from '../../../assets/flag-icons/UA.svg';
import US from '../../../assets/flag-icons/US.svg';

const iconMappingFlags = {
  AG,
  AU,
  BE,
  BZ,
  CA,
  CH,
  CL,
  CO,
  CZ,
  DE,
  DK,
  ES,
  EU,
  FR,
  GB,
  GR,
  IE,
  IN,
  IT,
  JP,
  KR,
  MX,
  NL,
  NO,
  NZ,
  PL,
  SW,
  TR,
  UA,
  US,
};

const iconMapping = {
  ...iconMappingFlags,
  planet: planetIcon,
  arrowLearnMore: arrowLearnMoreIcon,
  arrowLearnMoreBlack: arrowLearnMoreBlackIcon,
  arrowLearnMoreWhite: arrowLearnMoreWhiteIcon,
  premiumPrice: premiumPriceIcon,
  regularPrice: regularPriceIcon,
  checked: checkedIcon,
  unchecked: uncheckedIcon,
  close: closeIcon,
  closeDropdown: closeDropdownIcon,
  closeWhite: closeWhiteIcon,
  coin: coinIcon,
  checkmark: checkmarkIcon,
  copy: copyIcon,
  copied: copiedIcon,
  filterIcon: filterIcon,
  filterIconGreen: filterIconGreen,
  resetFiltersIcon: resetFiltersIcon,
};

export { iconMapping, iconMappingFlags };
