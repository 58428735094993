import { FC, useMemo, useState, useEffect } from 'react';
import c from 'classnames';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { MarketplaceItemDetailed, marketplaceItemType } from 'store';
import { Button, GeoLabel, Icon, Tabs } from 'components';
import { useTranslation } from 'react-i18next';
import { PriceBlock } from 'components/marketplace-card/price-block';
import { useWindowDimensions } from 'hooks';
import { breakpoints } from 'constants/breakpoints';
import { parseByNewLines } from 'utils';
import { TFunction } from 'i18next';

dayjs.extend(relativeTime);

export type PreviewItemProps = {
  marketplaceItem: MarketplaceItemDetailed | null;
  balance?: number;
  goNext?: () => void;
  isPremiumUser: boolean;
};

const PreviewItem: FC<PreviewItemProps> = ({
  marketplaceItem,
  balance = 0,
  goNext,
  isPremiumUser,
}) => {
  const { width } = useWindowDimensions();
  const isMobile = width < breakpoints.md;
  const [isOpenRedeem, setIsOpenRedeem] = useState(false);

  console.log('marketplaceItem', marketplaceItem);
  const descriptionTabId = '1';
  const howToRedeemTabId = '2';

  const makeTabs = (
    t: TFunction<'translation', undefined>,
    secondTabPresent?: boolean
  ) => [
    {
      id: descriptionTabId,
      title: secondTabPresent ? t('Tab.InventoryCardDetails.Description') : '',
    },
    ...(secondTabPresent
      ? [
          {
            id: howToRedeemTabId,
            title: t('Tab.InventoryCardDetails.HowToRedeem'),
          },
        ]
      : []),
  ];

  const description = (
    <p
      id="marketplace-description"
      className={c(
        'marketplace-description font-grotesk typography-basic font-bold text-gray-text-card mb-[15px] max-h-[55%] overflow-y-scroll',
        {
          'px-[15px] !text-[14px] !leading-[18px]': isMobile,
        }
      )}
    >
      {parseByNewLines({
        str: marketplaceItem?.description,
        classNameForSpan:
          'font-grotesk typography-basic font-bold text-gray-text-card',
      })}
    </p>
  );

  const isEnoughBalance = isPremiumUser
    ? balance >= (marketplaceItem?.premiumPrice || 0)
    : balance >= (marketplaceItem?.price || 0);

  const isPremium =
    marketplaceItem?.type.toLowerCase() ===
    marketplaceItemType.premium.toLowerCase();
  const isBuffpay =
    marketplaceItem?.type.toLowerCase() ===
    marketplaceItemType.buffpayOnly.toLowerCase();

  const { t } = useTranslation();

  const marketplaceType = useMemo(() => {
    if (isBuffpay) {
      return { text: t('MarketplaceCard.Buffpay') };
    }

    if (isPremium) {
      return { text: t('MarketplaceCard.Premium') };
    }
  }, [t, marketplaceItem?.type]);

  const actionItems = (
    <>
      {marketplaceItem?.isOutOfStock ? null : marketplaceItem?.isPremium &&
        !isPremiumUser ? (
        <div className={isMobile ? 'mx-[15px] flex' : 'mt-auto w-full'}>
          {isMobile ? (
            <PriceBlock
              isPremium
              price={marketplaceItem?.premiumPrice!}
              className="w-[150px]"
            />
          ) : null}
          <Button
            className={c('mt-auto bg-gold-primary w-full', {
              'w-full mb-[40px]': isMobile,
            })}
            // TODO: need to know what to do here
            onClick={() => {}}
          >
            <span className="mr-[8px] font-grotesk font-bold typography-basic">
              {t(
                isMobile
                  ? 'BuyItemCard.BecomePremium.Mobile'
                  : 'BuyItemCard.BecomePremium'
              )}
            </span>
            <Icon name="arrowLearnMoreBlack" />
          </Button>
        </div>
      ) : isEnoughBalance ? (
        <div
          className={c('flex w-full mt-auto', {
            'px-[15px]': isMobile,
            'pb-[40px]': !isBuffpay,
          })}
        >
          {marketplaceItem?.premiumPrice ? (
            <Button
              className={c('flex-1 bg-gold-primary', {
                'mr-[5px]': !isPremiumUser,
              })}
              onClick={goNext}
              disabled={!isPremiumUser}
            >
              <span className="mr-[8px] font-grotesk font-bold typography-basic">
                {marketplaceItem?.premiumPrice}
              </span>
              <Icon name="coin" />
            </Button>
          ) : null}
          {marketplaceItem?.price ? (
            <Button
              className={c('flex-1', {
                'ml-[5px]': marketplaceItem?.premiumPrice,
              })}
              disabled={isPremiumUser}
              onClick={goNext}
            >
              <span className="mr-[8px] font-grotesk font-bold typography-basic">
                {marketplaceItem?.price}
              </span>
              <Icon name="coin" />
            </Button>
          ) : null}
        </div>
      ) : (
        <div className="mt-auto flex items-center">
          <div
            className={c('flex-1 flex', { 'pl-[15px] mb-[40px]': isMobile })}
          >
            {marketplaceItem?.premiumPrice ? (
              <PriceBlock
                isPremium
                price={marketplaceItem?.premiumPrice}
                className="mr-[10px]"
              />
            ) : null}
            {marketplaceItem?.price ? (
              <PriceBlock
                price={marketplaceItem?.price}
                className="mr-[10px]"
              />
            ) : null}
          </div>
          <Button
            className={c(
              '!bg-dark-card flex-1 border-gray-text border-[1px] w-[200px] py-[13px] px-[19px]',
              { 'mr-[15px] mb-[40px]': isMobile }
            )}
            disabled
          >
            <span className="font-grotesk font-bold typography-basic text-gray-text">
              {t('BuyItemCard.notEnough')}
            </span>
          </Button>
        </div>
      )}
    </>
  );

  const geoInfo = (
    <div
      className={c('flex items-center', { 'px-[15px] mb-[14px]': isMobile })}
    >
      <GeoLabel
        geo={marketplaceItem?.geoGroup}
        spanClassName={isMobile ? '!text-[16px] !leading-[22px]' : undefined}
      />
      <span
        className={c('uppercase typography-basic font-bold', {
          'text-gray-text': isBuffpay,
          'text-gold-primary': isPremium,
          '!text-[16px] !leading-[22px]': isMobile,
        })}
      >
        {marketplaceType?.text}
      </span>
    </div>
  );

  const tabsContent = [
    {
      id: descriptionTabId,
      component: (
        <div className="pt-[20px] pb-[29px] pl-[20px] flex flex-col h-full w-[calc(100%+20px)]">
          {description}
          {geoInfo}
          {actionItems}
        </div>
      ),
    },
    ...(marketplaceItem?.howToRedeem
      ? [
          {
            id: howToRedeemTabId,
            component: (
              <div className="py-[22px] pl-[20px] h-full">
                <div className="overflow-y-scroll max-h-[90%]">
                  {parseByNewLines({
                    str: marketplaceItem?.howToRedeem,
                    classNameForSpan:
                      'font-grotesk text-[14px] font-semibold leading-[18px] text-gray-text-card',
                  })}
                </div>
              </div>
            ),
          },
        ]
      : []),
  ];

  const hasHowToRedeem = Boolean(marketplaceItem?.howToRedeem);

  const tabTitles = useMemo(
    () => makeTabs(t, hasHowToRedeem),
    [t, marketplaceItem?.howToRedeem]
  );

  const [activeTab, setActiveTab] = useState(descriptionTabId);

  useEffect(() => {
    setActiveTab(descriptionTabId);
  }, [marketplaceItem]);

  const handleRedeemTab = () => {
    setIsOpenRedeem((prevValue) => !prevValue);
  };

  return (
    <>
      {isMobile ? (
        <div className="w-full flex justify-center mb-[16px] relative">
          <img
            src={marketplaceItem?.img}
            alt={marketplaceItem?.name}
            className={c(
              'w-full max-h-[245px] object-cover rounded-[8px] mx-[15px]',
              {
                'border-gold-primary border-[1px]': isPremium,
              }
            )}
          />
          {marketplaceItem?.isOutOfStock ? (
            <>
              <div className="absolute h-full w-full top-0 right-0 bg-card-out-of-stock"></div>
              <div className="absolute top-[40%] py-[6px] px-[8px] border-pink rounded-[4px] border-[1px] bg-dark-card">
                <p className="typography-title mb-[2px]">
                  {t('MarketplaceCard.OutOfStock')}
                </p>
                {marketplaceItem?.lastPurchaseDate ? (
                  <p className="text-gray-text-card font-grotesk typography-card-label font-bold">
                    {t('MarketplaceCard.LastPurchase', {
                      time: dayjs(marketplaceItem.lastPurchaseDate).fromNow(
                        true
                      ),
                    })}
                  </p>
                ) : null}
              </div>
            </>
          ) : null}
        </div>
      ) : null}
      {hasHowToRedeem && !isMobile ? (
        <Tabs
          shouldDisableBorder={!hasHowToRedeem}
          className="h-full w-[100%] translate-x-[-20px] h-inherit pb-[27px]"
          tabsContainerClassName={c('ml-[20px]', {})}
          tabsClassName="!px-[0px] mr-[16px] font-bold tracking-[0.02em]"
          activeTabId={activeTab}
          onChange={setActiveTab}
          titles={tabTitles}
          content={tabsContent}
        />
      ) : null}
      {isMobile || hasHowToRedeem ? null : description}
      {hasHowToRedeem && !isMobile ? null : geoInfo}

      {isMobile ? description : null}
      {isMobile && hasHowToRedeem ? (
        <div className="mt-[36px] mx-[15px] h-[32px] flex items-center justify-between ">
          <div className="flex items-center justify-start font-grotesk text-[14px] capitalize font-semibold ">
            {t('BuyItemCard.HowToRedeem')}
          </div>
          <button
            className={c(
              'h-full w-[90px] flex items-center justify-center border-[1px] rounded-[39px] text-[14px] font-semibold font-grotesk leading-[20px] capitalize text-white',
              { 'bg-white text-toggle-button': isOpenRedeem }
            )}
            onClick={handleRedeemTab}
          >
            {isOpenRedeem ? 'hide' : 'expand'}
          </button>
        </div>
      ) : null}
      {isOpenRedeem ? (
        <div className="min-h-[15px] mx-[15px] mt-[15px] font-grotesk text-[14px] font-semibold leading-[18px] text-gray-text-card">
          {parseByNewLines({
            str: marketplaceItem?.howToRedeem,
            classNameForSpan:
              'font-grotesk text-[14px] font-semibold leading-[18px] text-gray-text-card',
          })}
          {marketplaceItem?.howToRedeem}
        </div>
      ) : null}
      {hasHowToRedeem && isMobile ? (
        <div className="mt-[15px] mx-[15px] w-[95%] h-[1px] bg-neutral-30 mb-[20px]"></div>
      ) : null}
      {hasHowToRedeem && !isMobile ? null : actionItems}
      {isBuffpay ? (
        <p className="typography-card-label font-grotesk mx-auto text-gray-label font-bold text-center pt-[10px] md:pt-0 pb-[20px] md:pb-0">
          {t('MarketplaceCard.Mobile.NeedAnAccount')}
        </p>
      ) : null}
    </>
  );
};

export { PreviewItem };
