import { FC, useState, useEffect } from 'react';
import c from 'classnames';
import { Icon } from 'components';
import { TIMEOUT_CLEAR_COPIED_ALERT } from 'constants/inventory';
import './gift-code.css';

export type GiftCodeItemProps = {
  title: string;
  copiedTextAlert: string;
  className?: string;
  giftCode?: string;
  activeUntilDate?: string;
  activeUntil?: string;
};

const GiftCodeItem: FC<GiftCodeItemProps> = ({
  title,
  activeUntilDate,
  copiedTextAlert,
  className,
  giftCode,
}) => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isCopied) {
      timeoutId = setTimeout(() => {
        setIsCopied(false);
      }, TIMEOUT_CLEAR_COPIED_ALERT);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isCopied]);

  const handleCopyClick = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  return (
    <div
      className={c(
        'pl-[15px] pr-[17px] h-[48px] w-[360px] rounded-[8px] bg-neutral-40 text-gray-text flex justify-between relative',
        className
      )}
    >
      <div className="flex-1 flex flex-col justify-around items-start ">
        <h3 className="font-grotesk text-[12px] leading-[16px] capitalize ">
          {title}
        </h3>
        <div className="font-grotesk text-[12px] font-semibold leading-[15.6px] uppercase mb-[5px] max-w-[300px] gift-code-text">
          {giftCode || activeUntilDate}
        </div>
      </div>
      <div className="flex-2 flex items-center justify-center ">
        <Icon
          name="copy"
          className={c('w-[20px] h-[20px] cursor-pointer', {})}
          onClick={() => {
            handleCopyClick!(giftCode!);
          }}
        />
        {isCopied ? (
          <div className="flex items-center justify-around absolute top-[9px]  md:-top-[30px] right-[50px] md:-right-[15px] w-[93px] md:w-[89px] h-[30px]  bg-copy-message rounded-[8px] ">
            <div className="absolute md:w-[22px]  md:h-[22px] md:top-[15px] md:right-[32px] md:rotate-45 bg-copy-message "></div>
            <h3 className="font-grotesk text-[13px] ml-[6px] md:text-[12px] font-semibold capitalize z-50">
              {copiedTextAlert}
            </h3>
            <Icon name="copied" className="mr-[6px]" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export { GiftCodeItem };
