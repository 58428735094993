import { FC, useState } from 'react';
import c from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, Input, Icon } from 'components';
import { useWindowDimensions } from 'hooks';
import { breakpoints } from 'constants/breakpoints';

export type ConfirmWithEmailProps = {
  email?: string;
  goBack?: () => void;
  goNext?: (code: string) => void;
  errorInCode: boolean;
};

const ConfirmWithEmail: FC<ConfirmWithEmailProps> = ({
  email,
  goBack,
  goNext,
  errorInCode,
}) => {
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const { width } = useWindowDimensions();
  const isMobile = width < breakpoints.md;

  return (
    <div className={c('h-full flex flex-col', { 'px-[15px]': isMobile })}>
      <p className="font-grotesk font-bold text-gray-text-card text-[14px] leading-[18px] md:typography-basic">
        {t('BuyItemCard.SendCode.Title')}
      </p>
      <p className="font-grotesk font-bold text-primary text-[14px] leading-[18px] md:typography-basic mb-[20px]">
        {email}
      </p>
      <Input
        value={code}
        inputClassName={c('w-[335px]', {
          '!border-pink': errorInCode,
          '!w-full': isMobile,
        })}
        label={t('BuyItemCard.SendCode.Placeholder')}
        onChange={(e) => {
          setCode(e.target.value);
        }}
      />
      {errorInCode ? (
        <p className="text-pink font-grotesk font-light typography-card-label mt-[6px]">
          {t('BuyItemCard.SendCode.InvalidCode')}
        </p>
      ) : null}
      {isMobile ? (
        <div
          className={c('mt-[42px] w-[100%] h-[1px] bg-neutral-30 mb-[20px]', {
            '!mt-[20px]': errorInCode,
          })}
        />
      ) : null}
      <div className={c('flex mt-auto', { 'mb-[40px]': isMobile })}>
        <Button
          className="flex-1 mr-[5px] !bg-dark-card border-gray-text border-[1px]"
          onClick={goBack}
        >
          <span className="mr-[8px] font-grotesk font-bold typography-basic text-gray-text">
            {t('BuyItemCard.SendCode.BackButton')}
          </span>
          <Icon name="arrowLearnMoreWhite" />
        </Button>
        <Button
          className="flex-1 ml-[5px]"
          disabled={!Boolean(code)}
          onClick={() => {
            goNext && goNext(code);
          }}
        >
          <span className="mr-[8px] font-grotesk font-bold typography-basic">
            {t('BuyItemCard.SendCode.ConfirmButton')}
          </span>
          <Icon name="arrowLearnMoreBlack" />
        </Button>
      </div>
    </div>
  );
};

export { ConfirmWithEmail };
