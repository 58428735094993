import { useEffect, useState } from 'react';

export type SwipeAnimationMobile = (params: {
  onClose: () => void;
  isOpened?: boolean;
}) => {
  styles: Record<string, any>;
  onCardClose: () => void;
  isCardOpened: boolean;
};

const animationTimeOpen = 80;
const animationTimeClose = 300;

export const useSwipeAnimationMobile: SwipeAnimationMobile = ({
  onClose,
  isOpened,
}) => {
  const [isResolvedOpened, setIsResolvedOpened] = useState(false);
  const [animationStart, setAnimationStart] = useState(false);

  const styles = { 'translate-y-[100%]': animationStart || !isResolvedOpened };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (animationStart) {
      timeoutId = setTimeout(() => {
        onClose();
        setAnimationStart(false);
        setIsResolvedOpened(false);
      }, animationTimeClose);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [animationStart]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isOpened) {
      timeoutId = setTimeout(() => {
        setIsResolvedOpened(true);
      }, animationTimeOpen);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isOpened]);

  const closeCard = () => {
    setAnimationStart(true);
  };

  return {
    styles,
    onCardClose: closeCard,
    isCardOpened: isResolvedOpened,
  };
};
