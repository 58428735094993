import { useEffect, useState } from "react";
import { signIn } from "../api";
import { getConfig, getUser, useAppDispatch, userSelector } from "../store";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

const useAuth = () => {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  const user = useSelector(userSelector);

  useEffect(() => {
    const auth = async () => {
      try {
        const token = searchParams.get("token");
        const email = searchParams.get("email");
        const password = searchParams.get("password");
        if (!user.token || token || (email && password)) {
          setIsLoading(true);
          await signIn({ email, password, token });
        }
        dispatch(getUser());
        dispatch(getConfig());
      } catch (e) {
        console.log(e);
      }
      setIsLoading(false)
    };
    auth();
  }, []);

  return {
     isLoading,
  }
};

export default useAuth;
