import { FC } from 'react';
import c from 'classnames';
import { parseByNewLines } from 'utils';
import { gradientForCards } from 'constants/theme';

export type HowToRedeemTabProps = {
  howToRedeem: string;
};

const HowToRedeemTab: FC<HowToRedeemTabProps> = ({ howToRedeem }) => {
  return (
    <div className={c('w-[380px] pt-[18px] px-[20px] h-full pb-[35px]')}>
      <div className="overflow-auto h-full pb-[20px]">
        {parseByNewLines({
          str: howToRedeem,
          classNameForSpan:
            'font-grotesk text-[14px] font-semibold leading-[20px] text-gray-text-card tracking-[0.01em]',
        })}
      </div>
      <div
        className="w-[367px] opacity-100 absolute bottom-[0px] h-[60px]"
        style={{
          background: gradientForCards,
        }}
      />
    </div>
  );
};

export { HowToRedeemTab };
