import { TFunction } from 'i18next';

export const marketplaceFilters = {
  global: 'global',
};

export const sortingValues = {
  priceAsc: 'PRICE_ASC',
  priceDesc: 'PRICE_DESC',
  popularityAsc: 'POPULARITY_ASC',
  popularityDesc: 'POPULARITY_DESC',
};
export const makeSortingOptions = (t: TFunction<'translation', undefined>) => [
  {
    value: sortingValues.priceAsc,
    label: t('Tabs.Marketplace.Sorting.Price.LowToHigh'),
  },
  {
    value: sortingValues.priceDesc,
    label: t('Tabs.Marketplace.Sorting.Price.HighToLow'),
  },
  {
    value: sortingValues.popularityAsc,
    label: t('Tabs.Marketplace.Sorting.Popularity.LowToHigh'),
  },
  {
    value: sortingValues.popularityDesc,
    label: t('Tabs.Marketplace.Sorting.Popularity.HighToLow'),
  },
];
export const defaultSortingValue = sortingValues.popularityAsc;
export const defaultSortingLabel =
  'Tabs.Marketplace.Sorting.Popularity.LowToHigh';
