import axios from 'axios';

const linkForJSON = process.env.REACT_APP_CONFIG_URL;

export const initConfig = async () => {
  if (linkForJSON) {
    try {
      const response = await axios.get(linkForJSON);

      return response.data;
    } catch (e) {
      return {};
    }
  }
};
