import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUser as getUserApi } from 'api';

const getUser = createAsyncThunk('user/user', async () => {
  const response = await getUserApi();

  return response?.data;
});

export const userInitialState = {
  token: localStorage.getItem('token') || "",
  isPremium: false,
  email: '',
  balance: 0,
};

const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      const account = payload.account;

      state.isPremium = account?.hasSubscription;
      state.email = account?.email;
      state.balance = account?.balance;
    });
  },
});

const userReducer = userSlice.reducer;

export { userReducer, getUser };
