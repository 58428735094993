import { FC, ReactNode } from 'react';
import c from 'classnames';

type TabId = string;

type Title = {
  id: TabId;
  title: string;
};

type Content = {
  id: TabId;
  component: ReactNode;
};

export type TabsProps = {
  titles: Title[];
  content: Content[];
  activeTabId?: TabId;
  onChange?: (tabId: string) => void;
  className?: string;
  tabsContainerClassName?: string;
  tabsClassName?: string;
  contentOnLeft?: JSX.Element;
  shouldDisableBorder?: boolean;
  showMobileFilters?: boolean;
  isMobile?: boolean;
};

const Tabs: FC<TabsProps> = ({
  titles,
  content,
  activeTabId,
  className,
  onChange,
  tabsClassName,
  tabsContainerClassName,
  contentOnLeft,
  shouldDisableBorder,
  showMobileFilters,
  isMobile,
}) => {
  const activeContent = activeTabId
    ? content.find((c) => c.id === activeTabId)?.component
    : content[0].component;

  return (
    <div className={className}>
      <div className={c('flex justify-between', tabsContainerClassName)}>
        <div
          className={c('flex border-b-[1px] border-neutral-50 w-full', {
            'border-none': shouldDisableBorder,
            'opacity-20': isMobile && showMobileFilters,
          })}
        >
          {titles.map((title) => (
            <div
              className={c(
                'px-[8px] py-[5px] uppercase font-bold typography-tab-title cursor-pointer transition-all tracking-[0.02em] md:flex-[inherit] flex-1 md:text-left text-center',
                {
                  'text-primary border-b-[1px] border-primary cursor-default':
                    activeTabId === title.id,
                },
                tabsClassName
              )}
              key={title.id}
              onClick={() => {
                onChange && onChange(title.id);
              }}
            >
              {title.title}
            </div>
          ))}
        </div>
        {contentOnLeft}
      </div>
      {activeContent}
    </div>
  );
};

export { Tabs };
