import { FC, useState, useEffect } from 'react';
import c from 'classnames';
import { useTranslation } from 'react-i18next';
import { SideActionCard, Select, Icon, Label } from 'components';
import { MarketplaceFilters } from '../use-marketplace-filters';
import { ActiveFiltersMenu } from './active-filters-menu';

export const maxItemToShow = 5;

export type FiltersCardProps = Omit<
  MarketplaceFilters,
  'sorting' | 'filterOptions' | 'onMobileFilterChange'
> & {};

const FiltersCard: FC<FiltersCardProps> = ({
  categories,
  geo,
  type,
  outOfStock,
  activeFilters,
  resetFilters,
  resetOneFilter,
}) => {
  const { t } = useTranslation();
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  useEffect(() => {
    if (isMenuOpened && activeFilters.length <= maxItemToShow) {
      setIsMenuOpened(false);
    }
  }, [activeFilters.length]);

  return (
    <SideActionCard className="w-[400px] mb-[10px] flex-1 flex flex-col relative h-[50%] overflow-hidden">
      <h6 className="font-bold typography-card-title mb-[16px] text-white">
        {t('FiltersCard.Heading')}
      </h6>
      <div className="flex mb-[10px]">
        <Select
          isMulti
          placeholder={t('FiltersCard.CategoriesFilter')}
          selectedOptions={categories.selected}
          setSelectedOptions={categories.onChange}
          containerClassName="w-[50%] mr-[10px]"
          options={categories.options}
          allItemsLabel={t('FiltersCard.CategoriesFilter.All')}
        />
        <Select
          isMulti
          placeholder={t('FiltersCard.GeoFilter')}
          selectedOptions={geo.selected}
          setSelectedOptions={geo.onChange}
          containerClassName="w-[50%]"
          options={geo.options}
          allItemsLabel={t('FiltersCard.GeoFilter.All')}
        />
      </div>
      <div>
        <Select
          isMulti
          placeholder={t('FiltersCard.TypeFilter')}
          selectedOptions={type.selected}
          setSelectedOptions={type.onChange}
          containerClassName="w-[49%]"
          options={type.options}
          allItemsLabel={t('FiltersCard.TypeFilter.All')}
        />
      </div>

      <div
        className="flex items-center mb-[16px] pt-[26px] cursor-pointer mt-auto"
        onClick={() => {
          outOfStock.onChange(!outOfStock.checked);
        }}
      >
        <Icon
          name={outOfStock.checked ? 'checked' : 'unchecked'}
          className="w-[15px] h-[15px]"
        />
        <span className="ml-[8px] mt-[3px] typography-basic tracking-[0.02em] text-white">
          {t('FiltersCard.HideOutOfStock')}
        </span>
      </div>
      <div className="mb-[18px] bg-neutral-40 h-[1px] w-full"></div>
      {activeFilters?.length ? (
        <div>
          <div className="flex justify-between align-center">
            <p className="text-white font-bold typography-basic tracking-[0.02em]">
              {t('FiltersCard.SelectedFilters')}
            </p>
            <p
              className="text-gray-text font-grotesk typography-basic cursor-pointer mb-[7px] font-medium"
              onClick={resetFilters}
            >
              {t('FiltersCard.ResetAll')}
            </p>
          </div>
          <div className="flex flex-wrap translate-x-[-5px]">
            {activeFilters.slice(0, maxItemToShow).map((filter, index) => (
              <Label
                type={filter.type}
                key={filter.value}
                className={c('mx-[5px] my-[4px]')}
                text={filter.value}
                onClick={() => {
                  resetOneFilter(filter);
                }}
              />
            ))}
            {activeFilters.slice(maxItemToShow).length ? (
              <Label
                showMore
                isActive={isMenuOpened}
                className="mx-[5px] my-[4px]"
                text={`+${activeFilters.slice(maxItemToShow).length}`}
                onClick={() => {
                  setIsMenuOpened(true);
                }}
              />
            ) : null}
          </div>
          <ActiveFiltersMenu
            isOpened={isMenuOpened}
            closeMenu={() => {
              setIsMenuOpened(false);
            }}
            resetOneFilter={resetOneFilter}
            activeFilters={activeFilters}
          />
        </div>
      ) : (
        <div className="typography-basic text-white">
          {t('FiltersCard.NoSelectedFilters')}
        </div>
      )}
    </SideActionCard>
  );
};

export { FiltersCard };
