import { useState } from 'react';
import { Button } from 'components';

const link =
  process.env.REACT_APP_IFRAME_URL ||
  'https://buff-marketplace.dev.sogoservices.com/';

const desktopDimensions = {
  height: `${process.env.REACT_APP_IFRAME_DESKTOP_HEIGHT || 740}px`,
  width: `${process.env.REACT_APP_IFRAME_DESKTOP_WIDTH || 1255}px`,
};

const mobileDimensions = {
  height: `${process.env.REACT_APP_IFRAME_MOBILE_HEIGHT || 810}px`,
  width: `${process.env.REACT_APP_IFRAME_MOBILE_WIDTH || 375}px`,
};

const TestIframe = () => {
  const [isMobile, setIsMobile] = useState(false);

  return (
    <div className="bg-gray-200 h-full">
      <div className="flex items-center my-[10px] justify-center">
        <h2 className="typography-card-title py-[10px] md:py-[20px] px-[20px] md:px-[50px]">
          IFRAME: {isMobile ? 'Mobile' : 'Desktop'} version
        </h2>
        <Button
          onClick={() => {
            setIsMobile((m) => !m);
          }}
        >
          SWITCH VERSION
        </Button>
      </div>

      <iframe
        src={link}
        title="Buff Marketplace"
        className="mx-auto border-4 border-lime-500"
        style={isMobile ? mobileDimensions : desktopDimensions}
      />
    </div>
  );
};

export { TestIframe };
