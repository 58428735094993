import {
  configureStore,
  combineReducers,
  PreloadedState,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { marketplaceReducer } from './marketplace-slice';
import { userReducer } from './user-slice';
import { inventoryReducer } from './inventory-slice';
import { configReducer } from './config-slice';

const reducer = combineReducers({
  marketplace: marketplaceReducer,
  user: userReducer,
  inventory: inventoryReducer,
  config: configReducer,
});

const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: reducer,
    preloadedState,
  });

const store = setupStore();

export type RootState = ReturnType<typeof reducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = typeof store.dispatch;

const useAppDispatch = () => useDispatch<AppDispatch>();

export { store, useAppDispatch };
