import { FC } from 'react';
import c from 'classnames';

type MobileFilterItemProps = {
  label: string | number;
  className?: string;
  isActive?: boolean;
  id?: string | number;
  onClick?: (value: MobileFilterItemProps['id']) => void;
};

const MobileFilterItem: FC<MobileFilterItemProps> = ({
  label,
  className,
  isActive,
  id,
  onClick,
}) => {
  return (
    <button
      onClick={() => {
        id && onClick && onClick(id);
      }}
      className={c(
        'w-[168px] h-[85px] flex items-center justify-center bg-neutral-40 text-[20px] leading-[26px] font-railroad font-bold uppercase rounded-[8px] text-white',
        className,
        { 'border-[1px] border-primary !text-primary': isActive }
      )}
    >
      {label}
    </button>
  );
};

export { MobileFilterItem };
