import { api } from './axios-client';
import { urls } from './constants';
import { storage } from 'utils';

type SignInPayload = {
  email: string | null;
  password: string | null;
  token: string | null;
};

const signIn = async ({ email, password, token }: SignInPayload) => {
  let appToken = token ?? "";
  if (!token && email && password) {
    const { data } = await api.post(urls.signIn, {
      email,
      password,
    });
    appToken = data.data.token;
  }

  storage.set('token', appToken);
  email && storage.set('email', email);
  password && storage.set('password', password);
};

export { signIn };
