import { FC, ButtonHTMLAttributes } from 'react';
import c from 'classnames';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {};

const Button: FC<ButtonProps> = ({ children, className, ...props }) => {
  return (
    <button
      {...props}
      className={c(
        'transition-all flex justify-center items-center bg-primary text-dark-card rounded-[100px] py-[13px] px-[57px]',
        {
          'bg-neutral-30': props.disabled,
        },
        className
      )}
    >
      {children}
    </button>
  );
};

export { Button };
