import { FC, useMemo } from 'react';
import c from 'classnames';
import { inventorySelector } from 'store';
import { useSelector } from 'react-redux';
import { GeoLabel } from 'components';
import { t } from 'i18next';
import { GiftCodeItem } from './gift-code-item';
import { parseByNewLines } from 'utils';
import { BUFF_PAY, PREMIUM } from 'constants/inventory';

export type DescriptionTabProps = {
  className?: string;
  onlyOneTab?: boolean;
  parsedCode?: string;
};

const DescriptionTab: FC<DescriptionTabProps> = ({
  className,
  onlyOneTab,
  parsedCode,
}) => {
  const currentItem = useSelector(inventorySelector);
  const currentItemInfo = currentItem.currentItem;
  const description = currentItemInfo?.description;
  const geo = currentItemInfo?.marketplaceItem.geoGroup;
  const type = currentItemInfo?.marketplaceItem.type;
  const category = currentItemInfo?.marketplaceItem.category;

  const inventoryType = useMemo(() => {
    if (type === BUFF_PAY) {
      return { text: t('InventoryCard.Buffpay') };
    }
    if (type === PREMIUM) {
      return { text: t('InventoryCard.Premium') };
    }
  }, [type, t]);

  return (
    <div
      className={c(
        'pt-[18px] px-[20px] flex flex-col overflow h-full pb-[35px]',
        { 'pt-[0px]': onlyOneTab },
        className
      )}
    >
      <div className="h-auto pb-[0px] max-h-[50%]  min-h-auto overflow-auto pr-[3px] w-[370px]">
        {parseByNewLines({
          str: description,
          classNameForSpan:
            'font-grotesk text-[14px] font-semibold leading-[20px] text-gray-text-card tracking-[0.01em]',
        })}
      </div>
      <div className="flex items-center justify-start text-railroad mt-[16px] mb-[8px] relative">
        <div className="flex items-center text-railroad mr-[10px]">
          <GeoLabel geo={geo} />
        </div>
        {type ? (
          <div className="flex items-center text-railroad mr-[10px]">
            <span
              className={c('typography-basic font-bold', {
                'text-gray-text': type === BUFF_PAY,
                'text-gold-primary': type === PREMIUM,
              })}
            >
              {inventoryType?.text}
            </span>
          </div>
        ) : null}
        {category ? (
          <div className="flex items-center text-railroad">
            <span
              className={c(' typography-basic font-bold', {
                'text-primary': category === 'RAFFLE PRIZE',
              })}
            >
              {category}
            </span>
          </div>
        ) : null}
      </div>

      {parsedCode ? (
        <GiftCodeItem
          title={t('InventoryCard.Description.GiftCode')}
          copiedTextAlert={t('InventoryCard.Description.CopiedCode')}
          giftCode={parsedCode}
          className="mt-auto mb-[15px]"
        />
      ) : null}
    </div>
  );
};

export { DescriptionTab };
